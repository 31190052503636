<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.go_back"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="150"
                height="150"
                alt="bidanvitacare"
                :src="require('@/assets/icon/info_back.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  Apakah Anda yakin akan keluar
                </div>

                <div>
                  dari halaman ini?
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                to="/home"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Keluar
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.go_back = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- filter -->
        <v-dialog v-model="dialog.filter"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Filter
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second mt-6 pb-0">
              <div>
                <div class="body-2 mb-2">
                  Klien
                </div>

                <v-autocomplete
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. Cintya Zenit"

                  :search-input.sync="search_klien"
                  :loading="process.klien"

                  :items="list_klien"
                  item-value="id"
                  item-text="name"
                  :item-color="set_color"

                  :no-filter="false"

                  clearable
                  @click:clear="filter.client_id = ''"

                  v-model="filter.client_id">
                  <template v-slot:item="data">
                    <v-avatar
                      size="24">
                      <v-img
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="data.item.image_url !== '' ? data.item.image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <span
                      class="body-2 text-capitalize ml-2">
                      {{ data.item.name }}
                    </span>
                  </template>

                  <template v-slot:selection="data">
                    <v-avatar
                      size="24">
                      <v-img
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="data.item.image_url !== '' ? data.item.image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <span
                      class="body-2 text-capitalize ml-2">
                      {{ data.item.name }}
                    </span>
                  </template>
                </v-autocomplete>
              </div>

              <div>
                <div class="body-2 mb-2">
                  Terapis
                </div>

                <v-autocomplete
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Contoh. John Doe"

                  :search-input.sync="search_terapis"
                  :loading="process.terapis"

                  :items="list_terapis"
                  item-value="id"
                  item-text="name"
                  :item-color="set_color"

                  :no-filter="false"

                  clearable
                  @click:clear="filter.therapist_id = ''"

                  v-model="filter.therapist_id">
                  <template v-slot:item="data">
                    <v-avatar
                      size="24">
                      <v-img
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="data.item.image_url !== '' ? data.item.image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <span
                      class="body-2 text-capitalize ml-2">
                      {{ data.item.name }}
                    </span>
                  </template>

                  <template v-slot:selection="data">
                    <v-avatar
                      size="24">
                      <v-img
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="data.item.image_url !== '' ? data.item.image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <span
                      class="body-2 text-capitalize ml-2">
                      {{ data.item.name }}
                    </span>
                  </template>
                </v-autocomplete>
              </div>

              <div>
                <div class="body-2 mb-2">
                  Cabang
                </div>

                <v-select
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Pilih Cabang"

                  :items="list_store"
                  item-value="id"
                  item-text="name"
                  :item-color="set_color"

                  clearable
                  @click:clear="filter.store_id = ''"

                  :no-filter="false"

                  v-model="filter.store_id">
                </v-select>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.filter = false; fetch();"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.filter = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- hapus jadwal -->
        <v-dialog v-model="dialog.delete"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="150"
                height="150"
                alt="bidanvitacare"
                :src="require('@/assets/icon/hapus_info.png')"
                class="ma-auto my-6">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                Apakah Anda yakin akan menghapus jadwal ini?
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="delete_schedule()"
                :loading="process.form"
                :disabled="process.form"

                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Saya yakin
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.delete = false"
                :disabled="process.form"

                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- gambar rumah -->
        <v-dialog v-model="dialog.gambar_rumah"
          persistent
          scrollable>
          <v-card v-if="Object.keys(detail).length > 0"
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Gambar Rumah
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second px-4 pb-2 mt-4">
              <div>
                <v-img v-if="selected.gambar_rumah_index === ''"
                  contain
                  width="100%"
                  height="250"
                  style="border: 5px solid #0000000d; border-radius: 10px;"
                  alt="bidanvitacare"
                  :src="detail.address_image_url !== '' ? detail.address_image_url : require('@/assets/image/image_default.png')"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>

                <v-img v-else
                  contain
                  width="100%"
                  height="250"
                  style="border: 5px solid #0000000d; border-radius: 10px;"
                  alt="bidanvitacare"
                  :src="JSON.parse(detail.address_image_array_json)[selected.gambar_rumah_index].image_url"
                  class="ma-auto">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>

              <div v-if="detail.address_image_array_json.length > 0"
                class="mt-2">
                <no-ssr>
                  <swiper
                    class="swiper"
                    ref="swiper"
                    :options="{ slidesPerView: 'auto' }">
                    <swiper-slide
                      class="py-3 pl-0"
                      style="max-width: 120px;">
                      <v-img
                        @click="selected.gambar_rumah_index = ''"

                        contain
                        width="100%"
                        height="100px"
                        style="border: 5px solid #0000000d; border-radius: 10px;"
                        alt="bidanvitacare"
                        :src="detail.address_image_url !== '' ? detail.address_image_url : require('@/assets/image/image_default.png')"
                        class="ma-auto cursor-pointer">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>

                        <v-icon v-if="selected.gambar_rumah_index === ''"
                          color="green"
                          style="position: absolute; right: 0;">
                          mdi-check-circle
                        </v-icon>
                      </v-img>
                    </swiper-slide>

                    <swiper-slide
                      v-for="(item, index) in JSON.parse(detail.address_image_array_json)"
                      :key="index"
                      class="py-3"
                      :class="index === 0 ? 'pl-4 pr-4' : index < JSON.parse(detail.address_image_array_json).length - 1 ? 'pr-4' : 'pr-4'"
                      style="max-width: 120px;">
                      <v-img
                        @click="selected.gambar_rumah_index = index"

                        contain
                        width="100%"
                        height="100px"
                        alt="bidanvitacare"
                        :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                        class="ma-auto cursor-pointer"
                        style="border: 5px solid #0000000d; border-radius: 10px;">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>

                        <v-icon v-if="selected.gambar_rumah_index === index"
                          color="green"
                          style="position: absolute; right: 0;">
                          mdi-check-circle
                        </v-icon>
                      </v-img>
                    </swiper-slide>
                  </swiper>
                </no-ssr>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="dialog.gambar_rumah = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Tutup
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.success">
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="125"
                height="125"
                alt="bidanvitacare"
                :src="require('@/assets/icon/info_berhasil.png')"
                class="ma-auto my-6">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  {{ message.success }}
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- generate so -->
        <v-dialog v-model="dialog.generate_so"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="150"
                height="150"
                alt="bidanvitacare"
                :src="require('@/assets/icon/generate_so.png')"
                class="ma-auto my-6">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                Apakah Anda yakin ingin mengenerate so?
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="save_generate_so()"
                :loading="process.form"
                :disabled="process.form"

                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Saya yakin
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.generate_so = false"
                :disabled="process.form"

                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- switch jadwal -->
        <v-dialog v-model="dialog.switch_jadwal"
          persistent
          scrollable>
          <v-card v-if="Object.keys(detail).length > 0"
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Switch Jadwal
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text
              class="body-2 text-first line-text-second px-4 pb-0 mt-3">
              <div
                class="mb-6">
                <div class="body-2 mb-2">
                  Jadwal Layanan
                </div>

                <v-select
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Pilih Jadwal Layanan"

                  :items="detail.details"
                  item-value="id"
                  item-text="item_name"
                  :item-color="set_color"

                  hide-details

                  return-object

                  v-model="form.details_object">
                  <template v-slot:item="data">
                    <v-avatar
                      size="24">
                      <v-img
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="data.item.item_image_url !== '' ? data.item.item_image_url : require('@/assets/image/image_default.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <span
                      class="body-2 text-capitalize ml-2">
                      {{ data.item.item_name }}
                    </span>
                  </template>

                  <template v-slot:selection="data">
                    <v-avatar
                      size="24">
                      <v-img
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="data.item.item_image_url !== '' ? data.item.item_image_url : require('@/assets/image/image_default.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <span
                      class="body-2 text-capitalize ml-2">
                      {{ data.item.item_name }}
                    </span>
                  </template>
                </v-select>

                <div v-if="Object.keys(form.details_object).length > 0"
                  class="d-flex align-center mt-4 pa-3"
                  style="border: 1px dashed #bbb; border-radius: 10px;">
                  <div>
                    Durasi. <strong>{{ form.details_object.duration_minutes }} Menit</strong>
                  </div>

                  <v-spacer />

                  <div>
                    Qty. <strong>{{ form.details_object.qty }}</strong>
                  </div>
                </div>

                <!-- <div v-if="Object.keys(form.details_object).length > 0"
                  class="mt-4 pa-3"
                  style="border: 1px dashed #bbb; border-radius: 10px;">
                  <div
                    v-for="(item, index) in form.details_visit"
                    :key="index">
                    <div
                      class="d-flex align-center">
                      <div
                        style="opacity: 70%;">
                        Terapis
                      </div>

                      <v-spacer />

                      <div>
                        {{ item.therapist_name }}
                      </div>
                    </div>

                    <div
                      class="d-flex align-center">
                      <div
                        style="opacity: 70%;">
                        Klien
                      </div>

                      <v-spacer />

                      <div>
                        {{ item.client_name }}
                      </div>
                    </div>

                    <div
                      class="d-flex align-center">
                      <div
                        style="opacity: 70%;">
                        Tanggal
                      </div>

                      <v-spacer />

                      <div>
                        {{ item.datetime | date }}
                      </div>
                    </div>

                    <div
                      class="d-flex align-center">
                      <div
                        style="opacity: 70%;">
                        Pukul
                      </div>

                      <v-spacer />

                      <div>
                        {{ item.datetime | time }}
                      </div>
                    </div>

                    <div
                      class="d-flex align-center">
                      <div
                        style="opacity: 70%;">
                        Toleransi
                      </div>

                      <v-spacer />

                      <div>
                        <span v-if="item.toleransi_datetime !== '' && item.toleransi_datetime !== null">
                          {{ item.toleransi_datetime | time }}
                        </span>

                        <span v-else>
                          -
                        </span>
                      </div>
                    </div>

                    <v-divider v-if="index < form.details_visit.length - 1"
                      class="mx-4 mb-3 mt-0"
                      style="border-top: 1px dashed #bbb;" />
                  </div>
                </div> -->
              </div>

              <div
                class="mb-6">
                <div class="body-2 mb-2">
                  Jadwal 1
                </div>

                <v-select
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Pilih Jadwal 1"

                  :items="form.details_visit"
                  item-value="id"
                  item-text="therapist_name"
                  :item-color="set_color"

                  hide-details

                  return-object

                  @change="form.data2 = {}"

                  v-model="form.data1">
                  <template v-slot:item="data">
                    <v-avatar
                      size="24">
                      <v-img
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="data.item.therapist_image_url !== '' ? data.item.therapist_image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <span
                      class="body-2 text-capitalize ml-2">
                      {{ data.item.therapist_name }}
                    </span>
                  </template>

                  <template v-slot:selection="data">
                    <v-avatar
                      size="24">
                      <v-img
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="data.item.therapist_image_url !== '' ? data.item.therapist_image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <span
                      class="body-2 text-capitalize ml-2">
                      {{ data.item.therapist_name }}
                    </span>
                  </template>
                </v-select>

                <div v-if="Object.keys(form.data1).length > 0"
                  class="mt-4 pa-3 font-weight-light line-text-second black--text"
                  style="border: 1px dashed #bbb; border-radius: 10px;">
                  <div
                    class="d-flex align-center">
                    <div
                      style="opacity: 70%;">
                      Tanggal
                    </div>

                    <v-spacer />

                    <div>
                      {{ form.data1.datetime | date }}
                    </div>
                  </div>

                  <div
                    class="d-flex align-center">
                    <div
                      style="opacity: 70%;">
                      Pukul
                    </div>

                    <v-spacer />

                    <div>
                      {{ form.data1.datetime | time }}
                    </div>
                  </div>

                  <!-- <div
                    class="d-flex align-center">
                    <div
                      style="opacity: 70%;">
                      Toleransi
                    </div>

                    <v-spacer />

                    <div>
                      <span v-if="form.data1.toleransi_datetime !== '' && form.data1.toleransi_datetime !== null">
                        {{ form.data1.toleransi_datetime | time }}
                      </span>

                      <span v-else>
                        -
                      </span>
                    </div>
                  </div> -->
                </div>
              </div>

              <div
                class="mb-6">
                <div class="body-2 mb-2">
                  Jadwal 2
                </div>

                <v-select
                  filled
                  rounded
                  dense
                  :color="set_color"
                  placeholder="Pilih Jadwal 2"

                  :items="form.details_visit"
                  item-value="id"
                  item-text="therapist_name"
                  :item-color="set_color"

                  hide-details

                  return-object

                  v-model="form.data2">
                  <template v-slot:item="data">
                    <v-avatar
                      size="24">
                      <v-img
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="data.item.therapist_image_url !== '' ? data.item.therapist_image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <span
                      class="body-2 text-capitalize ml-2">
                      {{ data.item.therapist_name }}
                    </span>
                  </template>

                  <template v-slot:selection="data">
                    <v-avatar
                      size="24">
                      <v-img
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="data.item.therapist_image_url !== '' ? data.item.therapist_image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <span
                      class="body-2 text-capitalize ml-2">
                      {{ data.item.therapist_name }}
                    </span>
                  </template>
                </v-select>

                <div v-if="Object.keys(form.data2).length > 0"
                  class="mt-4 pa-3 font-weight-light line-text-second black--text"
                  style="border: 1px dashed #bbb; border-radius: 10px;">
                  <div
                    class="d-flex align-center">
                    <div
                      style="opacity: 70%;">
                      Tanggal
                    </div>

                    <v-spacer />

                    <div>
                      {{ form.data2.datetime | date }}
                    </div>
                  </div>

                  <div
                    class="d-flex align-center">
                    <div
                      style="opacity: 70%;">
                      Pukul
                    </div>

                    <v-spacer />

                    <div>
                      {{ form.data2.datetime | time }}
                    </div>
                  </div>

                  <!-- <div
                    class="d-flex align-center">
                    <div
                      style="opacity: 70%;">
                      Toleransi
                    </div>

                    <v-spacer />

                    <div>
                      <span v-if="form.data2.toleransi_datetime !== '' && form.data2.toleransi_datetime !== null">
                        {{ form.data2.toleransi_datetime | time }}
                      </span>

                      <span v-else>
                        -
                      </span>
                    </div>
                  </div> -->
                </div>
              </div>

              <div v-if="message.error.length > 0"
                class="text-center my-2 d-flex align-center">
                <div
                  class="error--text">
                  <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                  <span
                    class="ml-1">
                    {{ message.error }}
                  </span>
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="save_switch_jadwal()"
                :loading="process.form"
                :disabled="process.form || Object.keys(form.details_object).length < 1 || Object.keys(form.data1).length < 1 || Object.keys(form.data2).length < 1"

                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Simpan
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.switch_jadwal = false"
                :disabled="process.form"

                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- check available -->
        <v-dialog v-model="dialog.check_available"
          persistent
          scrollable>
          <v-card
            class="border-radius pa-2">
            <v-card-title
              class="d-flex align-center py-0 px-3 text-right popup-header">
              <v-avatar
                size="30"
                tile>
                <v-img
                  alt="bidanvitacare"
                  :src="require('@/assets/logo/favicon.png')">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center">
                      <v-progress-circular
                        size="20"
                        indeterminate
                        :color="set_color">
                      </v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-avatar>

              <span
                class="body-1 font-weight-bold text-capitalize ml-2">
                Jadwal Bentrok
              </span>
            </v-card-title>

            <v-divider
              class="mx-4 mt-3 mb-2"/>

            <v-card-text v-if="check_switch_available.length > 0"
              class="body-2 text-first line-text-second px-4 pb-2">
              <v-row>
                <v-col
                  v-for="(item, index) in check_switch_available"
                  :key="index"
                  cols="12">
                  <v-list
                    v-if="check_switch_available.length > 0"
                    flat
                    dense
                    three-line
                    color="#FFFFFF40"
                    class="border-radius"
                    style="border: 1px dashed #bbb;">
                    <v-list-item>
                      <v-list-item-avatar
                        size="40"
                        tile
                        class="mt-3"
                        style="border: 2px solid #ffffff; border-radius: 5px !important;">
                        <v-img
                          contain
                          width="100%"
                          height="100%"
                          alt="bidanvitacare"
                          :src="item.item_image_url !== '' ? item.item_image_url : require('@/assets/image/image_default.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <div
                          class="body-2 font-weight-bold text-capitalize">
                          <span
                            class="two-line mb-1">
                            {{ item.item_name }}
                          </span>
                        </div>

                        <div
                          class="d-flex align-center">
                          <div>
                            Durasi. <strong>{{ item.item_duration_minutes }} Menit</strong>
                          </div>

                          <v-spacer />

                          <div>
                            Qty. <strong>{{ item.item_qty }}</strong>
                          </div>
                        </div>

                        <div>
                          <v-divider
                            class="my-3"
                            style="border-top: 1px dashed #bbb;" />
                        </div>

                        <!-- klien -->
                        <div
                          class="d-flex align-center">
                          <div>
                            Klien
                          </div>

                          <v-spacer />

                          <div
                            class="d-flex align-center">
                            <v-avatar
                              size="24"
                              class="mt-0"
                              style="border: 2px solid #ffffff;">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="item.client_image_url !== '' ? item.client_image_url : require('@/assets/image/member_default.jpg')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <span
                              class="caption font-weight-bold one-line text-capitalize ml-1">
                              {{ item.client_name }}
                            </span>
                          </div>
                        </div>

                        <div>
                          <v-divider
                            class="my-3"
                            style="border-top: 1px dashed #bbb;" />
                        </div>

                        <div>
                          <div
                            class="d-flex align-center font-weight-light mb-2">
                            <div
                              style="opacity: 70%;">
                              Tanggal
                            </div>

                            <v-spacer />

                            <div>
                              <span v-if="item.datetime !== '' && item.datetime !== null">
                                {{ item.datetime | date }}
                              </span>

                              <span v-else>
                                -
                              </span>
                            </div>
                          </div>

                          <div
                            class="d-flex align-center font-weight-light mb-2">
                            <div
                              style="opacity: 70%;">
                              Pukul
                            </div>

                            <v-spacer />

                            <div>
                              <span v-if="item.datetime !== '' && item.datetime !== null">
                                {{ item.datetime | time }}
                              </span>

                              <span v-else>
                                -
                              </span>
                            </div>
                          </div>

                          <!-- <div
                            class="d-flex align-center font-weight-light">
                            <div
                              style="opacity: 70%;">
                              Toleransi
                            </div>

                            <v-spacer />

                            <div>
                              <span v-if="item.toleransi_datetime !== '' && item.toleransi_datetime !== null">
                                {{ item.toleransi_datetime | time }}
                              </span>

                              <span v-else>
                                -
                              </span>
                            </div>
                          </div> -->
                        </div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>
              <v-btn
                @click="dialog.check_available = false"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  Tutup
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            color="transparent">
            <v-card
              flat
              color="transparent">
              <v-card-text
                class="pb-0 mt-1">
                <v-row
                  class="px-1">
                  <v-col
                    v-for="(item, index) in menu"
                    :key="index"
                    cols="4"
                    class="py-0 px-2">
                    <v-btn
                      @click="selected.menu = item.value"
                      block
                      rounded
                      elevation="0"
                      :class="selected.menu !== item.value ? '' : 'font-weight-bold'"
                      :color="selected.menu !== item.value ? '#e2e2e2' : set_color"
                      :style="selected.menu !== item.value ? 'color: #8c8c8c;' : 'color: #FFFFFF;'"
                      class="body-2 text-capitalize mb-6">
                      {{ item.text }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card-text
              class="py-0">
              <div
                class="d-flex align-center mb-4">
                <v-text-field
                  v-model="search_data"
                  @click:clear="search_data = ''; fetch();"
                  v-on:keyup.enter="fetch()"
                  filled
                  rounded
                  dense
                  single-line
                  hide-details
                  :color="set_color"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Cari data yang Anda butuhkan ?"
                  clearable>
                </v-text-field>

                <v-btn
                  @click="dialog.filter = true"
                  width="40"
                  height="40"
                  fab
                  elevation="0"
                  class="ml-3">
                  <v-icon
                    style="opacity: 60%;">
                    mdi-filter-variant
                  </v-icon>
                </v-btn>
              </div>

              <v-menu v-if="selected.menu !== 'non_tanggal'"
                ref="date"
                min-width="290px"
                max-width="290px"
                transition="scale-transition"
                offset-y
                :nudge-right="40"
                :close-on-content-click="false"
                v-model="picker.date">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    readonly
                    filled
                    rounded
                    dense
                    :color="set_color"
                    placeholder="Pilih Tanggal"

                    clearable
                    @click:clear="filter.date = ''; fetch();"

                    v-on="on"
                    v-bind="attrs"

                    hide-details

                    class="mb-4"

                    v-model="filter.date">
                  </v-text-field>
                </template>

                <v-date-picker
                  :color="set_color"
                  v-model="filter.date"
                  @input="picker.date = false; fetch();">
                </v-date-picker>
              </v-menu>

              <v-row>
                <v-col
                  cols="12"
                  md="6">
                  <v-btn
                    to="/jadwal/new/tambah"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_auth"
                    :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                    <div
                      class="btn_auth_in d-flex align-center justify-center"
                      :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                      <div>
                        <v-img
                          contain
                          width="20"
                          height="20"
                          alt="bidanvitacare"
                          :src="require('@/assets/icon/add_plus.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>

                      <div
                        class="ml-2">
                        Tambah Jadwal
                      </div>
                    </div>
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  md="6">
                  <v-btn
                    to="/jadwal/new/desktop"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_auth"
                    :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                    <div
                      class="btn_auth_in d-flex align-center justify-center"
                      :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                      <div>
                        <v-img
                          contain
                          width="20"
                          height="20"
                          alt="bidanvitacare"
                          :src="require('@/assets/icon/jadwal_besar.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>

                      <div
                        class="ml-2">
                        Jadwal Desktop
                      </div>
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-text
              class="pb-0">
              <v-row>
                <v-col
                  cols="12">
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 4 : list_data"
                    :key="index"
                    :loading="process.load"
                    :class="index < list_data.length - 1 ? 'mb-4' : ''"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-card v-if="!process.load && list_data.length > 0"
                          @click="sheet.detail = true; fetchDetail(item.schedule_id);"
                          flat
                          color="transparent">
                          <div
                            class="d-flex align-center">
                            <div
                              class="font-weight-bold black--text">
                              <span v-if="item.datetime !== ''">
                                {{ item.datetime | date }}
                              </span>

                              <span v-else>
                                Tanggal belum ditentukan
                              </span>
                            </div>

                            <v-spacer />

                            <div v-if="item.datetime !== ''"
                              class="font-weight-bold">
                              {{ item.datetime | time }}
                            </div>
                          </div>

                          <div>
                            <v-divider
                              class="my-2"
                              style="border-top: 1px dashed #bbb;" />
                          </div>

                          <div
                            class="d-flex align-center mb-1">
                            <div
                              class="d-flex align-center">
                              <v-avatar
                                size="22"
                                style="border: 2px solid #ffffff;">
                                <v-img
                                  width="100%"
                                  height="100%"
                                  alt="bidanvitacare"
                                  :src="item.customer_image_url !== '' ? item.customer_image_url : require('@/assets/image/member_default.jpg')"
                                  class="ma-auto">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular
                                        size="20"
                                        indeterminate
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-avatar>

                              <span
                                class="ml-1">
                                {{ item.customer_name }}
                              </span>
                            </div>

                            <v-spacer />

                            <div>
                              {{ item.store_name }}
                            </div>
                          </div>

                          <div>
                            <v-divider
                              class="my-2"
                              style="border-top: 1px dashed #bbb;" />
                          </div>

                          <div
                            class="mt-3 mb-2 pa-3 pt-2"
                            style="border: 2px dashed #bbb; border-radius: 10px;">
                            <v-chip
                              x-small
                              class="white--text"
                              :color="item.type === 'on_site' ? 'blue' : 'green'"
                              style="font-weight: 500;">
                              {{ item.type === 'on_site' ? 'On Site' : 'Home Care' }}
                            </v-chip>

                            <div
                              class="d-flex align-start my-1">
                              <v-avatar
                                tile
                                size="22"
                                style="border: 2px solid #ffffff;">
                                <v-img
                                  width="100%"
                                  height="100%"
                                  alt="bidanvitacare"
                                  :src="item.item_image_url !== '' ? item.item_image_url : require('@/assets/image/image_default.png')"
                                  class="ma-auto">
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height"
                                      align="center"
                                      justify="center">
                                      <v-progress-circular
                                        size="20"
                                        indeterminate
                                        :color="set_color">
                                      </v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                              </v-avatar>

                              <span
                                class="ml-1">
                                {{ item.item_name }}
                              </span>
                            </div>

                            <div
                              style="margin-left: 26px;"
                              class="d-flex align-center">
                              <div>
                                Durasi. <strong>{{ item.item_duration_minutes }} Menit</strong>
                              </div>

                              <v-spacer />

                              <div>
                                Qty. <strong>{{ item.qty }}x</strong>
                              </div>
                            </div>
                          </div>

                          <v-row>
                            <v-col
                              cols="12"
                              class="pt-1">
                              <v-card
                                flat
                                color="#FFFFFF40"
                                class="pa-3"
                                style="border: 2px dashed #bbb; border-radius: 10px;">
                                <div
                                  class="d-flex align-center">
                                  <div>
                                    Terapis
                                  </div>

                                  <v-spacer />

                                  <div v-if="item.therapist_name !== ''"
                                    class="d-flex align-center">
                                    <v-avatar
                                      size="22"
                                      style="border: 2px solid #ffffff;">
                                      <v-img
                                        width="100%"
                                        height="100%"
                                        alt="bidanvitacare"
                                        :src="item.therapist_image_url !== '' ? item.therapist_image_url : require('@/assets/image/member_default.jpg')"
                                        class="ma-auto">
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular
                                              size="20"
                                              indeterminate
                                              :color="set_color">
                                            </v-progress-circular>
                                          </v-row>
                                        </template>
                                      </v-img>
                                    </v-avatar>

                                    <span
                                      class="ml-1">
                                      {{ item.therapist_name }}
                                    </span>
                                  </div>

                                  <div v-else>
                                    -
                                  </div>
                                </div>

                                <div>
                                  <v-divider
                                    class="my-3"
                                    style="border-top: 1px dashed #bbb;" />
                                </div>

                                <div
                                  class="d-flex align-center">
                                  <div>
                                    Shift
                                  </div>

                                  <v-spacer />

                                  <div>
                                    {{ item.therapist_shift !== '' ? item.therapist_shift : '-' }}
                                  </div>
                                </div>

                                <div>
                                  <v-divider
                                    class="my-3"
                                    style="border-top: 1px dashed #bbb;" />
                                </div>

                                <div
                                  class="d-flex align-center">
                                  <div>
                                    Status Laporan
                                  </div>

                                  <v-spacer />

                                  <div class="text-uppercase font-weight-bold">
                                    {{ item.laporan_is_tuntas === '1' ? 'Tuntas' : 'Belum Tuntas' }}
                                  </div>
                                </div>

                                <div>
                                  <v-divider
                                    class="my-3"
                                    style="border-top: 1px dashed #bbb;" />
                                </div>

                                <div
                                  class="d-flex align-center">
                                  <div>
                                    Klien
                                  </div>

                                  <v-spacer />

                                  <div v-if="item.client_name !== ''"
                                    class="d-flex align-center">
                                    <v-avatar
                                      size="22"
                                      style="border: 2px solid #ffffff;">
                                      <v-img
                                        width="100%"
                                        height="100%"
                                        alt="bidanvitacare"
                                        :src="item.client_image_url !== '' ? item.client_image_url : require('@/assets/image/member_default.jpg')"
                                        class="ma-auto">
                                        <template v-slot:placeholder>
                                          <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center">
                                            <v-progress-circular
                                              size="20"
                                              indeterminate
                                              :color="set_color">
                                            </v-progress-circular>
                                          </v-row>
                                        </template>
                                      </v-img>
                                    </v-avatar>

                                    <span
                                      class="ml-1">
                                      {{ item.client_name }}
                                    </span>
                                  </div>

                                  <div v-else>
                                    -
                                  </div>
                                </div>

                                <div>
                                  <v-divider
                                    class="my-3"
                                    style="border-top: 1px dashed #bbb;" />
                                </div>

                                <div
                                  class="d-flex align-center">
                                  <div>
                                    Umur Klien
                                  </div>

                                  <v-spacer />

                                  <div class="text-uppercase font-weight-bold">
                                    {{ item.client_age === null ? '-' : item.client_age }}
                                  </div>
                                </div>

                                <div>
                                  <v-divider
                                    class="my-3"
                                    style="border-top: 1px dashed #bbb;" />
                                </div>

                                <div
                                  class="d-flex align-center">
                                  <div>
                                    Catatan Terapis
                                  </div>

                                  <v-spacer />

                                  <div class="text-uppercase font-weight-bold">
                                    {{ item.note !== '' ? item.note : '-' }}
                                  </div>
                                </div>

                                <div v-if="item.address_link_map !== ''">
                                  <v-divider
                                    class="my-3"
                                    style="border-top: 1px dashed #bbb;" />
                                </div>

                                <div v-if="item.address_link_map !== ''"
                                  class="d-flex align-center">
                                  <div>
                                    Alamat
                                  </div>

                                  <v-spacer />

                                  <div>
                                    <a
                                      :href="item.address_link_map"
                                      target="_blank">
                                      Lihat Lokasi
                                    </a>
                                  </div>
                                </div>

                                <div v-if="item.type === 'home_care'">
                                  <v-divider
                                    class="my-3"
                                    style="border-top: 1px dashed #bbb;" />
                                </div>

                                <div v-if="item.type === 'home_care'">
                                  <div v-if="item.transport_id !== '' && item.transport_id !== '0'"
                                    class="d-flex align-center">
                                    <div>
                                      Transport ({{ item.transport_qty }} x {{ Number(item.transport_cost) | price }})
                                    </div>

                                    <v-spacer />

                                    <div class="font-weight-bold">
                                      {{ Number(item.transport_total_cost) | price }}
                                    </div>
                                  </div>

                                  <div v-else
                                    class="d-flex align-center">
                                    <div>
                                      Transport
                                    </div>

                                    <v-spacer />

                                    <div class="font-weight-bold">
                                      -
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <v-divider
                                    class="my-3"
                                    style="border-top: 1px dashed #bbb;" />
                                </div>

                                <div
                                  class="d-flex align-center">
                                  <div>
                                    Penugasan
                                  </div>

                                  <v-spacer />

                                  <div class="text-uppercase font-weight-bold">
                                    {{ item.single_partner === '' ? '-' : item.single_partner }}
                                  </div>
                                </div>

                                <div>
                                  <v-divider
                                    class="my-3"
                                    style="border-top: 1px dashed #bbb;" />
                                </div>

                                <div
                                  class="d-flex align-center">
                                  <div>
                                    Down Payment
                                  </div>

                                  <v-spacer />

                                  <div class="text-uppercase font-weight-bold">
                                    <span v-if="Number(item.dp) > 0">
                                      {{ Number(item.dp) | price }}
                                    </span>

                                    <span v-else>
                                      -
                                    </span>
                                  </div>
                                </div>

                                <div>
                                  <v-divider
                                    class="my-3"
                                    style="border-top: 1px dashed #bbb;" />
                                </div>

                                <div
                                  class="d-flex align-center">
                                  <div>
                                    Diinput Oleh
                                  </div>

                                  <v-spacer />

                                  <div>
                                    {{ item.create_user_name }}
                                  </div>
                                </div>

                                <div>
                                  <v-divider
                                    class="my-3"
                                    style="border-top: 1px dashed #bbb;" />
                                </div>

                                <div class="font-italic">
                                  <div class="mb-1">
                                    *Catatan Khusus Admin
                                  </div>

                                  <div>
                                    {{ item.note_khusus !== '' ? item.note_khusus : 'Tidak ada catatan khusus admin' }}
                                  </div>
                                </div>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-card>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <div v-if="!process.load && pagination_data.total_data > 10 && limit_data < pagination_data.total_data"
                class="text-center my-4">
                <v-btn
                  @click="limit_data += 10"
                  :loading="process.limit"
                  small
                  rounded
                  outlined
                  elevation="0"
                  :color="set_color"
                  class="text-capitalize"
                  :style="`color: ${set_color};`">
                  Lebih banyak
                </v-btn>
              </div>

              <Empty v-if="!process.load && pagination_data.total_page < 1"
                class="py-6"
                margin="my-16"
                size="125"
                message="Jadwal" />
            </v-card-text>
          </v-card>
        </section>

        <!-- detail jadwal -->
        <v-bottom-sheet
          v-model="sheet.detail"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.detail = false; detail = {};"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.detail = false; detail = {};"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  Jadwal {{ detail.customer_name }}
                </span>
              </v-card-title>

              <v-card-text
                class="mt-16"
                style="height:calc(100vh - 130px); overflow: auto;">
                <v-skeleton-loader
                  :loading="process.load"
                  type="list-item-avatar-three-line, article, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, article, actions">
                  <div>
                    <div v-if="!process.load && Object.keys(detail).length > 0">
                      <v-card
                        flat
                        color="transparent"
                        class="py-0"
                        style="border-radius: 10px;">
                        <v-list
                          flat
                          dense
                          color="transparent">
                          <v-list-item
                            class="px-0">
                            <v-list-item-avatar
                              size="40"
                              style="border: 2px solid #ffffff; overflow: unset;">
                              <v-img
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="detail.customer_image_url !== '' ? detail.customer_image_url : require('@/assets/image/member_default.jpg')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title
                                class="body-2 font-weight-bold text-capitalize mb-2">
                                {{ detail.customer_name }}
                              </v-list-item-title>

                              <v-list-item-subtitle
                                class="body-2 font-weight-light black--text">
                                {{ detail.customer_mobilephone }}
                              </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action v-if="Number(detail.so_id) > 0">
                              <div
                                class="d-flex align-center">
                                <v-btn
                                  :to="`/invoice/${detail.so_uuid}`"
                                  target="_blank"
                                  min-width="36"
                                  width="36"
                                  height="36"
                                  rounded
                                  outlined
                                  elevation="0"
                                  :color="set_color"
                                  class="text-capitalize px-0"
                                  :style="`color: ${set_color};`">
                                  <v-icon>mdi-text-box-outline</v-icon>
                                </v-btn>

                                <v-btn
                                  :to="`/invoice/print/${detail.so_uuid}`"
                                  target="_blank"
                                  min-width="36"
                                  width="36"
                                  height="36"
                                  rounded
                                  elevation="0"
                                  :color="set_color"
                                  class="text-capitalize white--text px-0 ml-2">
                                  <v-icon>mdi-printer-outline</v-icon>
                                </v-btn>
                              </div>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-card>

                      <v-divider
                        class="my-1"
                        style="border-top: 1px dashed #bbb;" />

                      <div
                        class="d-flex align-center my-4">
                        <div>
                          <div
                            class="line-text-second">
                            {{ detail.type === 'on_site' ? 'On Site' : 'Home Care' }}
                          </div>

                          <div
                            class="caption font-weight-bold">
                            {{ detail.datetime | datetime }}
                          </div>
                        </div>

                        <v-spacer />

                        <v-chip
                          small
                          class="white--text"
                          color="green">
                          {{ detail.details.length }}x Layanan
                        </v-chip>
                      </div>

                      <v-divider
                        class="my-1"
                        style="border-top: 1px dashed #bbb;" />

                      <!-- krim wa -->
                      <v-row
                        align="center">
                        <v-col
                          cols="4">
                          <v-btn
                            @click="send_show(detail.is_show_therapist_bool)"
                            :loading="process.send_show"
                            :disabled="process.send_show"

                            block
                            rounded
                            :color="!detail.is_show_therapist_bool ? '#444444' : 'red'"
                            elevation="0"
                            class="body-2 white--text text-capitalize"
                            style="font-size: 13px !important;">
                            {{ !detail.is_show_therapist_bool ? 'Show' : 'Hide' }} Terapis
                          </v-btn>
                        </v-col>

                        <v-col
                          cols="4">
                          <v-btn
                            @click="send_ringkasan()"
                            :loading="process.send_ringkasan"
                            :disabled="process.send_ringkasan"

                            block
                            rounded
                            color="#444444"
                            elevation="0"
                            class="body-2 white--text text-capitalize d-flex align-center"
                            style="font-size: 13px !important;">
                            <v-img
                              contain
                              width="16"
                              height="16"
                              alt="bidanvitacare"
                              :src="require('@/assets/icon/whatsapp.png')"
                              class="ma-auto cursor-pointer">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>

                            <span
                              class="ml-1">
                              Ringkasan
                            </span>
                          </v-btn>
                        </v-col>

                        <v-col
                          cols="4">
                          <v-btn
                            @click="send_konfirmasi()"
                            :loading="process.send_konfirmasi"
                            :disabled="process.send_konfirmasi"

                            block
                            rounded
                            color="#444444"
                            elevation="0"
                            class="body-2 white--text text-capitalize d-flex align-center"
                            style="font-size: 13px !important;">
                            <v-img
                              contain
                              width="16"
                              height="16"
                              alt="bidanvitacare"
                              :src="require('@/assets/icon/whatsapp.png')"
                              class="ma-auto cursor-pointer">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>

                            <span
                              class="ml-1">
                              Konfirmasi
                            </span>
                          </v-btn>
                        </v-col>
                      </v-row>

                      <v-card v-if="detail.is_wa_ringkasan_bool || detail.is_wa_konfirmasi_bool || detail.so_id !== '0'"
                        flat
                        class="pa-3 mt-1 mb-4"
                        style="border: 2px dashed #bbb; border-radius: 10px;">
                        <ul
                          style="position: relative; left: -10px; opacity: 70%;">
                          <li v-if="detail.is_wa_ringkasan_bool"
                            class="mb-1">
                            Ringkasan jadwal telah dikirimkan
                          </li>

                          <li v-if="detail.is_wa_konfirmasi_bool" class="mb-1">
                            Konfirmasi jadwal telah dikirimkan
                          </li>

                          <li v-if="detail.so_id !== '0'">
                            Invoice sudah digenerate
                          </li>
                        </ul>
                      </v-card>

                      <v-divider
                        class="my-1"
                        style="border-top: 1px dashed #bbb;" />

                      <!-- item -->
                      <v-row>
                        <v-col
                          v-for="(item, index) in detail.details"
                          :key="index"
                          cols="12">
                          <v-card
                            flat
                            color="#FFFFFF40"
                            class="pa-3 pt-2"
                            style="border: 2px dashed #bbb; border-radius: 10px;">
                            <v-list
                              flat
                              dense
                              three-line
                              color="transparent"
                              class="py-0">
                              <v-list-item
                                class="px-0">
                                <v-list-item-avatar
                                  size="40"
                                  tile
                                  class="mt-3"
                                  style="border: 2px solid #ffffff; border-radius: 5px !important;">
                                  <v-img
                                    contain
                                    width="100%"
                                    height="100%"
                                    alt="bidanvitacare"
                                    :src="item.item_image_url !== '' ? item.item_image_url : require('@/assets/image/image_default.png')"
                                    class="ma-auto">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                          size="20"
                                          indeterminate
                                          :color="set_color">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                  <div
                                    class="mb-2">
                                    <v-chip
                                      x-small
                                      color="#424242"
                                      class="white--text d-flex justify-center"
                                      style="width: 65px;">
                                      {{ item.type === 'service' ? 'Layanan' : item.type === 'product' ? 'Produk' : 'Bahan' }}
                                    </v-chip>
                                  </div>

                                  <div
                                    class="body-2 text-capitalize">
                                    <div
                                      class="two-line font-weight-bold mb-1">
                                      {{ item.item_name }}
                                    </div>

                                    <div
                                      class="d-flex align-center">
                                      <div>
                                        Durasi. <strong>{{ item.duration_minutes }} Menit</strong>
                                      </div>

                                      <v-spacer />

                                      <div>
                                        Qty. <strong>{{ item.qty }}x</strong>
                                      </div>
                                    </div>
                                  </div>

                                  <div v-if="item.visit.length > 0">
                                    <v-divider
                                      class="my-3"
                                      style="border-top: 1px dashed #bbb;" />
                                  </div>

                                  <div v-if="item.visit.length > 0">
                                    Klien
                                  </div>

                                  <v-row v-if="item.visit.length > 0">
                                    <v-col
                                      v-for="(item_visit, index_visit) in item.visit"
                                      :key="index_visit"
                                      cols="12"
                                      class="py-2">
                                      <v-list
                                        flat
                                        dense
                                        three-line
                                        color="#FFFFFF40"
                                        class="border-radius"
                                        style="border: 1px dashed #bbb;">
                                        <v-list-item>
                                          <v-list-item-avatar
                                            size="40"
                                            class="mt-2"
                                            style="border: 2px solid #ffffff;">
                                            <v-img
                                              width="100%"
                                              height="100%"
                                              alt="bidanvitacare"
                                              :src="item_visit.client_image_url !== '' ? item_visit.client_image_url : require('@/assets/image/member_default.jpg')"
                                              class="ma-auto">
                                              <template v-slot:placeholder>
                                                <v-row
                                                  class="fill-height"
                                                  align="center"
                                                  justify="center">
                                                  <v-progress-circular
                                                    size="20"
                                                    indeterminate
                                                    :color="set_color">
                                                  </v-progress-circular>
                                                </v-row>
                                              </template>
                                            </v-img>
                                          </v-list-item-avatar>

                                          <v-list-item-content>
                                            <v-list-item-title
                                              class="body-2 font-weight-bold text-capitalize">
                                              {{ item_visit.client_name }}
                                            </v-list-item-title>

                                            <v-list-item-title
                                              class="body-2 font-weight-bold text-capitalize">
                                              {{ (detail.antropometri.find(x => x.client_id === item_visit.client_id) !== undefined ? detail.antropometri.find(x => x.client_id === item_visit.client_id).age : '-') === null || (detail.antropometri.find(x => x.client_id === item_visit.client_id) !== undefined ? detail.antropometri.find(x => x.client_id === item_visit.client_id).age : '-') === '' ? '-' : (detail.antropometri.find(x => x.client_id === item_visit.client_id) !== undefined ? detail.antropometri.find(x => x.client_id === item_visit.client_id).age : '-') }}
                                            </v-list-item-title>

                                            <v-list-item-subtitle
                                              class="body-2 font-weight-light line-text-second black--text"
                                              style="opacity: 70%;">
                                              <span v-if="item_visit.therapist_name !== ''"
                                                class="text-capitalize">
                                                Terapis. {{ item_visit.therapist_name }}
                                              </span>

                                              <span v-else>
                                                Terapis belum ditentukan
                                              </span>
                                            </v-list-item-subtitle>

                                            <v-list-item-subtitle
                                              class="body-2 font-weight-light line-text-second black--text">
                                              <v-divider
                                                class="my-2"
                                                style="border-top: 1px dashed #bbb;" />

                                              <div
                                                class="d-flex align-center">
                                                <div
                                                  style="opacity: 70%;">
                                                  Shift
                                                </div>

                                                <v-spacer />

                                                <div>
                                                  {{ item_visit.therapist_shift !== '' ? item_visit.therapist_shift : '-' }}
                                                </div>
                                              </div>

                                              <div
                                                class="d-flex align-center" v-if="item.type !== 'product'">
                                                <div
                                                  style="opacity: 70%;">
                                                  Tanggal
                                                </div>

                                                <v-spacer />

                                                <div>
                                                  <span v-if="item_visit.datetime !== '' && item_visit.datetime !== null">
                                                    {{ item_visit.datetime | date }}
                                                  </span>

                                                  <span v-else>
                                                    -
                                                  </span>
                                                </div>
                                              </div>

                                              <div
                                                class="d-flex align-center" v-if="item.type !== 'product'">
                                                <div
                                                  style="opacity: 70%;">
                                                  Pukul
                                                </div>

                                                <v-spacer />

                                                <div>
                                                  <span v-if="item_visit.datetime !== '' && item_visit.datetime !== null">
                                                    {{ item_visit.datetime | time }}
                                                  </span>

                                                  <span v-else>
                                                    -
                                                  </span>
                                                </div>
                                              </div>

                                              <!-- <div
                                                class="d-flex align-center">
                                                <div
                                                  style="opacity: 70%;">
                                                  Toleransi
                                                </div>

                                                <v-spacer />

                                                <div>
                                                  <span v-if="item_visit.toleransi_datetime !== '' && item_visit.toleransi_datetime !== null">
                                                    {{ item_visit.toleransi_datetime | time }}
                                                  </span>

                                                  <span v-else>
                                                    -
                                                  </span>
                                                </div>
                                              </div> -->
                                            </v-list-item-subtitle>
                                          </v-list-item-content>
                                        </v-list-item>
                                      </v-list>
                                    </v-col>
                                  </v-row>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>

                            <v-divider
                              class="mb-2"
                              style="border-top: 1px dashed #bbb;" />

                            <div
                              class="my-3">
                              {{ item.note !== '' ? item.note : 'Tidak ada catatan terapis' }}
                            </div>

                            <v-divider
                              class="my-2"
                              style="border-top: 1px dashed #bbb;" />

                            <!-- alat -->
                            <div
                              v-if="item.type !== 'product'"
                              class="my-4">
                              <div
                                class="body-2 mb-2">
                                Alat yang harus dibawa terapis
                              </div>

                              <div
                                class="body-2 text-capitalize">
                                <ul v-if="item.material_json_array.length > 0"
                                  style="position: relative; left: -10px;">
                                  <li
                                    v-for="(sub_item, sub_index) in item.material_json_array"
                                    :key="sub_index"
                                    :class="sub_index < item.material_json_array.length - 1 ? 'mb-1' : ''"
                                    v-html="sub_item.name">
                                  </li>
                                </ul>

                                <div v-else>
                                  -
                                </div>
                              </div>
                            </div>

                            <div v-if="item.type !== 'product'">
                              <div
                                class="body-2 mb-2">
                                Alat yang harus dipersiapkan customer
                              </div>

                              <div
                                class="body-2 text-capitalize">
                                <ul v-if="item.customer_prepare_json_array.length > 0"
                                  style="position: relative; left: -10px;">
                                  <li
                                    v-for="(sub_item, sub_index) in item.customer_prepare_json_array"
                                    :key="sub_index"
                                    :class="sub_index < item.customer_prepare_json_array.length - 1 ? 'mb-1' : ''"
                                    v-html="sub_item.value">
                                  </li>
                                </ul>

                                <div v-else>
                                  -
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </v-col>
                      </v-row>

                      <v-divider
                        class="my-1"
                        style="border-top: 1px dashed #bbb;" />

                      <div
                        class="mt-4 mb-2">
                        Jlm Item ({{ detail.details.length }})
                      </div>

                      <!-- dp -->
                      <div
                        class="d-flex align-center mb-4">
                        <div>
                          Down Payment
                        </div>

                        <v-spacer />

                        <div>
                          {{ detail.dp | price }}
                        </div>
                      </div>

                      <v-divider
                        class="my-1"
                        style="border-top: 1px dashed #bbb;" />

                      <!-- catatan khusus admin -->
                      <div
                        class="my-4">
                        <div class="font-weight-bold mb-2">
                          Penugasan
                        </div>

                        <div class="text-uppercase">
                          {{ detail.single_partner !== '' ? detail.single_partner : '-' }}
                        </div>
                      </div>

                      <v-divider
                        class="my-1"
                        style="border-top: 1px dashed #bbb;" />

                      <!-- catatan khusus admin -->
                      <div
                        class="my-4">
                        <div class="font-weight-bold mb-2">
                          Catatan Khusus Admin
                        </div>

                        <div>
                          {{ detail.note_khusus !== '' ? detail.note_khusus : 'Tidak ada catatan khusus admin' }}
                        </div>
                      </div>

                      <v-divider v-if="detail.type === 'home_care'"
                        class="my-1"
                        style="border-top: 1px dashed #bbb;" />

                      <!-- alamat -->
                      <div v-if="detail.type === 'home_care'">
                        <div
                          class="font-weight-bold mt-4 mb-2">
                          Alamat
                        </div>

                        <div
                          class="mb-2">
                          {{ detail.address_full !== '' ? detail.address_full : '-'}}
                        </div>

                        <div
                          class="mb-2">
                          {{ detail.address_subdistrict_name }}, {{ detail.address_city_name }}, {{ detail.address_province_name }}
                        </div>

                        <div
                          class="mb-4">
                          Keterangan. {{ detail.address_note !== '' ? detail.address_note : '-'}}
                        </div>
                      </div>

                      <div v-if="detail.type === 'home_care'">
                        <div
                          class="font-weight-bold mt-4 mb-2">
                          Gambar Rumah
                        </div>

                        <div
                          class="text-truncate mb-4">
                          <span v-if="detail.address_image_url !== ''"
                            @click="dialog.gambar_rumah = true"
                            class="cursor-pointer"
                            style="color: #1976d3;">
                            Lihat disini
                          </span>

                          <span v-else>
                            -
                          </span>
                        </div>
                      </div>

                      <div v-if="detail.type === 'home_care'">
                        <div
                          class="font-weight-bold mt-4 mb-2">
                          Link Map
                        </div>

                        <div
                          class="text-truncate mb-4">
                          <a v-if="detail.address_link_map !== ''"
                            :href="detail.address_link_map"
                            target="_blank">
                            {{ detail.address_link_map }}
                          </a>

                          <span v-else>
                            -
                          </span>
                        </div>
                      </div>

                      <v-divider v-if="detail.type === 'home_care'"
                        class="my-1"
                        style="border-top: 1px dashed #bbb;" />

                      <!-- transport -->
                      <div v-if="detail.type === 'home_care'"
                        class="my-4">
                        <div class="font-weight-bold mb-2">
                          Transport
                        </div>

                        <div v-if="detail.transport_id !== '' && detail.transport_id !== '0'">
                          <div
                            class="d-flex align-center mb-1">
                            <div>
                              {{ detail.transport_name }}
                            </div>

                            <v-spacer />

                            <div>
                              {{ Number(detail.transport_cost) | price }}
                            </div>
                          </div>

                          <div
                            class="d-flex align-center">
                            <div>
                              Qty ({{ detail.transport_qty }}x)
                            </div>

                            <v-spacer />

                            <div
                              class="font-weight-bold black--text">
                              {{ Number(detail.transport_total_cost) | price }}
                            </div>
                          </div>
                        </div>

                        <div v-else>
                          Transport belum ditentukan
                        </div>
                      </div>

                      <v-divider
                        class="my-1"
                        style="border-top: 1px dashed #bbb;" />

                      <!-- pendamping -->
                      <div
                        class="font-weight-bold mt-4 mb-2">
                        Pendamping
                      </div>

                      <div
                        class="d-flex align-center mb-2">
                        <div>
                          Nama Pendamping
                        </div>

                        <v-spacer />

                        <div
                          class="text-capitalize">
                          {{ detail.wali !== '' ? detail.wali : '-' }}
                        </div>
                      </div>

                      <div
                        class="d-flex align-center mb-2">
                        <div>
                          No. Handphone Pendamping
                        </div>

                        <v-spacer />

                        <div>
                          {{ detail.mobilephone_wali !== '' ? detail.mobilephone_wali : '-' }}
                        </div>
                      </div>

                      <div
                        class="d-flex align-center mb-2">
                        <div>
                          No. Handphone Lainnya
                        </div>

                        <v-spacer />

                        <div>
                          {{ detail.mobilephone_other !== '' ? detail.mobilephone_other : '-' }}
                        </div>
                      </div>
                    </div>
                  </div>
                </v-skeleton-loader>
              </v-card-text>

              <v-card v-if="!process.load && Object.keys(detail).length > 0"
                flat
                color="#FFFFFF"
                min-height="50"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col
                      cols="4"
                      class="py-0">
                      <v-btn
                        :to="`/jadwal/new/ubah/${detail.id}`"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                        <div
                          class="btn_grey_in d-flex align-center justify-center">
                          <div>
                            <v-img
                              contain
                              width="20"
                              height="20"
                              alt="bidanvitacare"
                              :src="require('@/assets/icon/calendar_edit.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div
                            class="blue--text ml-2">
                            Ubah
                          </div>
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="5"
                      class="py-0">
                      <v-btn v-if="Number(detail.so_id) < 1"
                        @click="dialog.generate_so = true"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                        <div
                          class="btn_grey_in d-flex align-center justify-center">
                          <div>
                            <v-img
                              contain
                              width="20"
                              height="20"
                              alt="bidanvitacare"
                              :src="require('@/assets/icon/generate_so.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div
                            class="green--text ml-2">
                            Generate SO
                          </div>
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="3"
                      class="py-0">
                      <div class="d-flex align-center">
                        <v-spacer />

                        <v-img @click="dialog.switch_jadwal = true"
                          contain
                          width="30"
                          height="30"
                          alt="bidanvitacare"
                          :src="require('@/assets/icon/switch_jadwal.png')"
                          class="cursor-pointer mr-3">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>

                        <v-img @click="dialog.delete = true"
                          contain
                          width="30"
                          height="30"
                          alt="bidanvitacare"
                          :src="require('@/assets/icon/trash.png')"
                          class="cursor-pointer">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  import moment from "moment"
  import "moment/locale/id"
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dialog: {
          go_back: false,

          filter: false,
          delete: false,

          gambar_rumah: false,

          success: false,

          generate_so: false,

          switch_jadwal: false,
          check_available: false
        },

        sheet: {
          detail: false,
        },

        selected: {
          menu: 'terapis',
          gambar_rumah_index: ''
        },

        menu: [
          {
            text: 'Terapis',
            value: 'terapis'
          },
          {
            text: 'Non Terapis',
            value: 'non_terapis'
          },
          {
            text: 'Non Tanggal',
            value: 'non_tanggal'
          }
        ],

        filter: {
          date: '',
          therapist_id: '',
          client_id: '',
          store_id: ''
        },

        search_data: '',

        detail: {},

        list_data: [],
        pagination_data: {},
        limit_data: 10,

        search_klien: '',
        list_klien: [],
        pagination_klien: {},

        search_terapis: '',
        list_terapis: [],
        pagination_terapis: {},

        list_store: [],

        picker: {
          date: false
        },

        check_switch_available: [],

        form: {
          details_object: {},
          details_visit: [],

          data1: {},
          data2: {},

          check_data1: {},
          check_data2: {}
        },

        process: {
          load: false,

          form: false,

          klien: false,
          terapis: false,

          send_show: false,
          send_ringkasan: false,
          send_konfirmasi: false
        },

        message: {
          success: '',
          error: '',
          errors: []
        },

        // SEO
        content: {
          url: '/jadwal/new',
          title: 'Jadwal',
          description: 'Jadwal Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'selected.menu': function(val) {
        if (val === 'non_tanggal') this.filter.date = ''

        this.fetch()
      },

      'limit_data': function() {
        this.process.limit = true

        this.fetch('limit')
      },

      'search_klien': function() {
        this.fetchKlien()
      },

      'search_terapis': function() {
        this.fetchTerapis()
      },

      'form.details_object': function() {
        this.form.details_visit = this.detail.details.filter(x => x.id === this.form.details_object.id)[0]?.visit
      },

      'form.data1': function() {
        this.form.check_data1 = {
          id: this.form.data1.id,
          therapist_id: this.form.data1.therapist_id,
          start_datetime: this.form.data1.toleransi_datetime === '' || this.form.data1.toleransi_datetime === null ? this.form.data1.datetime : this.form.data1.toleransi_datetime,
          end_datetime: moment(this.form.data1.datetime).add(Number(this.form.details_object.duration_minutes) * Number(this.form.details_object.qty), 'minutes').format('YYYY-MM-DD HH:mm')
        }

        if (Object.keys(this.form.data2).length > 0) this.checkSwitchAvailable()
      },

      'form.data2': function() {
        this.form.check_data2 = {
          id: this.form.data2.id,
          therapist_id: this.form.data2.therapist_id,
          start_datetime: this.form.data2.toleransi_datetime === '' || this.form.data2.toleransi_datetime === null ? this.form.data2.datetime : this.form.data2.toleransi_datetime,
          end_datetime: moment(this.form.data2.datetime).add(Number(this.form.details_object.duration_minutes) * Number(this.form.details_object.qty), 'minutes').format('YYYY-MM-DD HH:mm')
        }

        if (Object.keys(this.form.data1).length > 0) this.checkSwitchAvailable()
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetch()

      this.pop_state()
    },
    methods: {
      pop_state () {
        let t = this

        history.pushState(null, null, document.URL)

        window.addEventListener('popstate', function () {
          history.pushState(null, null, document.URL)

          t.dialog.go_back = true
        })
      },

      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search_data,
          limit: this.limit_data,

          ...(this.selected.menu === 'terapis' && !this.filter.therapist_id) && {'therapist_id[gt]': 0},
          ...(this.selected.menu === 'non_terapis' && !this.filter.therapist_id) && {therapist_id: 0},
          is_datetime_empty_bool: this.selected.menu === 'non_tanggal' ? true : false,

          ...(this.filter.therapist_id) && {therapist_id: this.filter.therapist_id},
          client_id: this.filter.client_id,
          store_id: this.filter.store_id,

          datetime: this.filter.date
        }

        await this.$axios.$get(`${process.env.API}admin/schedule_v3/data_visit`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list_data = response.results.data
            this.pagination_data = response.results.pagination
          }
        })
      },

      async fetchDetail (id) {
        this.process.load = true

        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}admin/schedule_v3/detail`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.detail = response.results.data
          }
        })
      },

      async delete_schedule () {
        this.message.error = ''

        this.process.form = true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/schedule_v3/delete`, {
          id: this.detail.id
        })
        .then((response) => {

          this.process.form = false

          if (response.status === 200) {
            this.dialog.delete = false

            this.sheet.detail = false

            this.fetch()
          } else {
            this.message.error = response.message
          }
        })
      },

      async fetchKlien () {
        this.process.klien = true

        let params = {
          search: this.search_klien,
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}admin/customer`, { params })
        .then((response) => {
          this.process.klien = false

          if (response.status === 200) {
            this.list_klien = response.results.data
            this.pagination_klien = response.results.pagination
          }
        })
      },

      async fetchTerapis () {
        this.process.terapis = true

        let params = {
          search: this.search_terapis,
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}admin/user/therapist-allow`, { params })
        .then((response) => {
          this.process.terapis = false

          if (response.status === 200) {
            this.list_terapis = response.results.data
            this.pagination_terapis = response.results.pagination

            this.fetchStore()
          }
        })
      },

      async fetchStore () {
        let params = {
          limit: 10000
        }

        await this.$axios.$get(`${process.env.API}admin/store`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.list_store = response.results.data
          }
        })
      },

      // send wa
      async send_show (type) {
        this.process.send_show = true

        await this.$axios.$post(`${process.env.API}admin/schedule_v3/update_show_therapist`, {
          id: this.detail.id,
          is_show_therapist_bool: !type ? true : false
        })
        .then((response) => {
          this.process.send_show = false

          if (response.status === 200) {
            this.dialog.success = true
            this.message.success = `Terima kasih, Anda telah berhasil ${!type ? 'menampilkan' : 'menutup'}  data ke terapis.`

            this.fetchDetail(this.detail.id)
          }
        })
      },

      async send_ringkasan () {
        this.process.send_ringkasan = true

        await this.$axios.$post(`${process.env.API}admin/schedule_v3/send_wa_ringkasan`, {
          id: this.detail.id
        })
        .then((response) => {
          this.process.send_ringkasan = false

          if (response.status === 200) {
            this.dialog.success = true
            this.message.success = 'Terima kasih, Anda telah berhasil mengirimkan wa ringkasan jadwal.'

            this.fetchDetail(this.detail.id)
          }
        })
      },

      async send_konfirmasi () {
        this.process.send_konfirmasi = true

        await this.$axios.$post(`${process.env.API}admin/schedule_v3/send_wa_konfirmasi`, {
          id: this.detail.id
        })
        .then((response) => {
          this.process.send_konfirmasi = false

          if (response.status === 200) {
            this.dialog.success = true
            this.message.success = 'Terima kasih, Anda telah berhasil mengirimkan wa konfirmasi jadwal.'

            this.fetchDetail(this.detail.id)
          }
        })
      },

      // generate so
      async save_generate_so () {
        this.process.form = true

        await this.$axios.$post(`${process.env.API}admin/schedule_v3/generate_so`, {
          id: this.detail.id
        })
        .then((response) => {
          this.process.form = false

          if (response.status === 200) {
            this.dialog.generate_so = false

            this.dialog.success = true
            this.message.success = 'Terima kasih, Anda telah berhasil mengenerate so.'

            this.fetchDetail(this.detail.id)
          }
        })
      },

      // cek datetime available
      async checkSwitchAvailable () {
        await this.$axios.$post(`${process.env.API}admin/schedule_v3/check_datetime/switch_available`, {
          data1: this.form.check_data1,
          data2: this.form.check_data2
        })
        .then((response) => {
          if (response.status === 200) {
            this.check_switch_available = response.results.data.data1

            this.dialog.check_available = this.check_switch_available.length > 0 ? true : false
          }
        })
      },

      async save_switch_jadwal () {
        this.message.error = ''

        this.process.form = true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/schedule_v3/switch_visit`, {
          data1: {
            id: this.form.data1.id,
            therapist_id: this.form.data2.therapist_id,
            therapist_shift: this.form.data1.therapist_shift
          },
          data2: {
            id: this.form.data2.id,
            therapist_id: this.form.data1.therapist_id,
            therapist_shift: this.form.data2.therapist_shift
          }
        })
        .then((response) => {

          this.process.form = false

          if (response.status === 200) {
            this.dialog.switch_jadwal = false

            this.form = {
              details_object: {},
              details_visit: [],

              data1: {},
              data2: {},

              check_data1: {},
              check_data2: {}
            }

            this.fetchDetail(this.detail.id)
          } else {
            this.message.error = response.message
          }
        })
      }
    }
  }
</script>
