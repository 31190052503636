<template>
  <div>
    <!-- gambar rumah -->
    <v-dialog v-model="dialog.gambar_rumah"
      persistent
      scrollable>
      <v-card v-if="Object.keys(detail).length > 0"
        class="border-radius pa-2">
        <v-card-title
          class="d-flex align-center py-0 px-3 text-right popup-header">
          <v-avatar
            size="30"
            tile>
            <v-img
              alt="bidanvitacare"
              :src="require('@/assets/logo/favicon.png')">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular
                    size="20"
                    indeterminate
                    :color="set_color">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>

          <span
            class="body-1 font-weight-bold text-capitalize ml-2">
            Gambar Rumah
          </span>
        </v-card-title>

        <v-divider
          class="mx-4 mt-3 mb-2"/>

        <v-card-text
          class="body-2 text-first line-text-second px-4 pb-2 mt-4">
          <div>
            <v-img v-if="selected.gambar_rumah_index === ''"
              contain
              width="100%"
              height="250"
              style="border: 5px solid #0000000d; border-radius: 10px;"
              alt="bidanvitacare"
              :src="detail.address_image_url !== '' ? detail.address_image_url : require('@/assets/image/image_default.png')"
              class="ma-auto">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular
                    indeterminate
                    :color="set_color">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>

            <v-img v-else
              contain
              width="100%"
              height="250"
              style="border: 5px solid #0000000d; border-radius: 10px;"
              alt="bidanvitacare"
              :src="JSON.parse(detail.address_image_array_json)[selected.gambar_rumah_index].image_url"
              class="ma-auto">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular
                    indeterminate
                    :color="set_color">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </div>

          <div v-if="detail.address_image_array_json.length > 0"
            class="mt-2">
            <no-ssr>
              <swiper
                class="swiper"
                ref="swiper"
                :options="{ slidesPerView: 'auto' }">
                <swiper-slide
                  class="py-3 pl-0"
                  style="max-width: 120px;">
                  <v-img
                    @click="selected.gambar_rumah_index = ''"

                    contain
                    width="100%"
                    height="100px"
                    style="border: 5px solid #0000000d; border-radius: 10px;"
                    alt="bidanvitacare"
                    :src="detail.address_image_url !== '' ? detail.address_image_url : require('@/assets/image/image_default.png')"
                    class="ma-auto cursor-pointer">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>

                    <v-icon v-if="selected.gambar_rumah_index === ''"
                      color="green"
                      style="position: absolute; right: 0;">
                      mdi-check-circle
                    </v-icon>
                  </v-img>
                </swiper-slide>

                <swiper-slide
                  v-for="(item, index) in JSON.parse(detail.address_image_array_json)"
                  :key="index"
                  class="py-3"
                  :class="index === 0 ? 'pl-4 pr-4' : index < JSON.parse(detail.address_image_array_json).length - 1 ? 'pr-4' : 'pr-4'"
                  style="max-width: 120px;">
                  <v-img
                    @click="selected.gambar_rumah_index = index"

                    contain
                    width="100%"
                    height="100px"
                    alt="bidanvitacare"
                    :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                    class="ma-auto cursor-pointer"
                    style="border: 5px solid #0000000d; border-radius: 10px;">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          size="20"
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>

                    <v-icon v-if="selected.gambar_rumah_index === index"
                      color="green"
                      style="position: absolute; right: 0;">
                      mdi-check-circle
                    </v-icon>
                  </v-img>
                </swiper-slide>
              </swiper>
            </no-ssr>
          </div>
        </v-card-text>

        <v-divider
          class="mx-4 mb-3 mt-0"/>

        <v-card-actions>
          <v-spacer/>

          <v-btn
            @click="dialog.gambar_rumah = false"
            large
            rounded
            elevation="0"
            class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
            <div
              class="btn_cancel_in">
              <v-icon
                size="20">
                mdi-close-thick
              </v-icon>

              <span
                class="ml-1">
                Tutup
              </span>
            </div>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- permission -->
    <v-dialog v-model="dialog.permission"
      persistent>
      <v-card
        class="border-radius">
        <v-card-title
          class="d-flex align-center pa-3 text-right popup-header">
          <v-avatar
            size="30"
            tile>
            <v-img
              alt="amanata"
              :src="require('@/assets/logo/favicon.png')">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular
                    indeterminate
                    :color="set_color">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>

          <span
            class="body-1 font-weight-bold text-capitalize ml-2">
            Permission Telah di block
          </span>
        </v-card-title>

        <v-divider
          class="mb-2"
          style="border-top: 2px dashed #e0e0e0;"/>

        <v-card-text
          class="body-2 text-first line-text-second mt-3">
          Silahkan aktifkan permission lokasi di perangkat Anda terlebih dahulu.
        </v-card-text>
      </v-card>
    </v-dialog>

    <section>
      <v-card
        flat
        color="transparent">
        <v-card-text
          class="pb-0">
          <div
            class="d-flex align-center">
            <v-avatar
              size="36"
              style="border: 2px solid #ffffff;">
              <v-img
                width="100%"
                height="100%"
                alt="bidanvitacare"
                :src="detail.customer_image_url !== '' ? detail.customer_image_url : require('@/assets/image/member_default.jpg')"
                class="ma-auto">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>

            <span
              class="font-weight-bold text-capitalize black--text ml-2">
              {{ detail.customer_name }}
            </span>

            <v-spacer />

            <v-btn
              @click="sheet.history = true; fetchHistory();"
              width="36"
              height="36"
              fab
              dark
              :color="set_color"
              elevation="0">
              <v-icon>mdi-clipboard-text-clock-outline</v-icon>
            </v-btn>
          </div>
        </v-card-text>

        <v-card-text
          class="pb-0">
          <v-divider
            class="mb-4"
            style="border-top: 1px dashed #bbb;" />

          <!-- tipe & umur -->
          <div
            class="d-flex align-center">
            <div>
              <div
                class="body-2 mb-1">
                Tipe
              </div>

              <div
                class="body-2 font-weight-bold black--text">
                {{ detail.type === 'on_site' ? 'On Site' : 'Home Care' }}
              </div>
            </div>

            <v-spacer />

            <div>
              <div
                class="body-2 mb-1">
                Umur
              </div>

              <div
                class="body-2 font-weight-bold black--text">
                {{ detail.age !== '' ? detail.antropometri.age : '-' }}
              </div>
            </div>
          </div>

          <v-divider
            class="my-4"
            style="border-top: 1px dashed #bbb;" />

          <!-- bb / tb / lila / lk -->
          <div
            class="d-flex align-center">
            <div>
              <div
                class="body-2 mb-1">
                BB
              </div>

              <div
                class="body-2 black--text font-weight-bold">
                {{ detail.antropometri.bb }} Kg
              </div>
            </div>

            <v-spacer v-if="detail.client_type === 'bayi' || detail.client_type === 'anak'" />

            <div v-if="detail.client_type === 'bayi' || detail.client_type === 'anak'">
              <div
                class="body-2 mb-1">
                TB
              </div>

              <div
                class="body-2 black--text font-weight-bold">
                {{ detail.antropometri.tb }} Cm
              </div>
            </div>

            <v-spacer v-if="detail.client_type === 'bayi' || detail.client_type === 'anak'" />

            <div v-if="detail.client_type === 'bayi' || detail.client_type === 'anak'">
              <div
                class="body-2 mb-1">
                LILA
              </div>

              <div
                class="body-2 black--text font-weight-bold">
                {{ detail.antropometri.lila }} Cm
              </div>
            </div>

            <v-spacer v-if="detail.client_type === 'bayi' || detail.client_type === 'anak'" />

            <div v-if="detail.client_type === 'bayi' || detail.client_type === 'anak'">
              <div
                class="body-2 mb-1">
                LK
              </div>

              <div
                class="body-2 black--text font-weight-bold">
                {{ detail.antropometri.lk }} Cm
              </div>
            </div>

            <v-spacer v-if="detail.client_type !== 'bayi' && detail.client_type !== 'anak'" />

            <div v-if="detail.client_type !== 'bayi' && detail.client_type !== 'anak'">
              <div
                class="body-2 mb-1">
                Tensi
              </div>

              <div
                class="body-2 black--text font-weight-bold">
                {{ detail.antropometri.tensi !== '' && detail.antropometri.tensi !== null ? detail.antropometri.tensi : '0.00' }} mmHg
              </div>
            </div>
          </div>

          <v-divider
            class="my-4"
            style="border-top: 1px dashed #bbb;" />

          <!-- klien -->
          <div
            class="d-flex align-center">
            <div
              class="body-2">
              Klien
            </div>

            <v-spacer />

            <div>
              <div
                class="d-flex align-center">
                <v-avatar
                  size="24"
                  class="mt-0"
                  style="border: 2px solid #ffffff;">
                  <v-img
                    width="100%"
                    height="100%"
                    alt="bidanvitacare"
                    :src="detail.client_image_url !== '' ? detail.client_image_url : require('@/assets/image/member_default.jpg')"
                    class="ma-auto">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          size="20"
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>

                <span
                  class="caption black--text font-weight-bold one-line text-capitalize ml-1">
                  {{ detail.client_name }}
                </span>
              </div>
            </div>
          </div>

          <v-divider
            class="my-4"
            style="border-top: 1px dashed #bbb;" />

          <!-- alat -->
          <div
            class="mb-4">
            <div
              class="body-2 font-weight-bold black--text mb-2">
              Alat yang harus dibawa terapis
            </div>

            <div
              class="body-2 text-capitalize">
              <ul
                v-for="(item, index) in detail.details" :key="index"
                style="position: relative; left: -10px;">
                <li
                  :class="index < detail.details.length - 1 ? 'mb-3' : ''" v-if="item.type !== 'product'">
                  <div
                    class="mb-1">
                    {{ item.item_name }}
                  </div>

                  <ul v-if="item.material_json_array.length > 0"
                    style="position: relative; left: -10px;">
                    <li
                      v-for="(sub_item, sub_index) in item.material_json_array"
                      :key="sub_index"
                      :class="sub_index < item.material_json_array.length - 1 ? 'mb-1' : ''"
                      v-html="sub_item.name">
                    </li>
                  </ul>

                  <div v-else>
                    -
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div>
            <div
              class="body-2 font-weight-bold black--text mb-2">
              Alat yang harus dipersiapkan customer
            </div>

            <div
              class="body-2 text-capitalize">
              <ul
                v-for="(item, index) in detail.details" :key="index"
                style="position: relative; left: -10px;">
                <li
                  :class="index < detail.details.length - 1 ? 'mb-3' : ''" v-if="item.type !== 'product'">
                  <div
                    class="mb-1">
                    {{ item.item_name }}
                  </div>

                  <ul v-if="item.customer_prepare_json_array.length > 0"
                    style="position: relative; left: -10px;">
                    <li
                      v-for="(sub_item, sub_index) in item.customer_prepare_json_array"
                      :key="sub_index"
                      :class="sub_index < item.customer_prepare_json_array.length - 1 ? 'mb-1' : ''"
                      v-html="sub_item.value">
                    </li>
                  </ul>

                  <div v-else>
                    -
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <v-divider
            class="my-4"
            style="border-top: 1px dashed #bbb;" />

          <!-- tanggal dan jam -->
          <v-row
            class="mb-2">
            <v-col
              cols="4"
              class="py-0">
              Tanggal & Waktu Kunjungan
            </v-col>

            <v-col
              cols="8"
              class="py-0">
              : {{ detail.details[0].datetime | date }} Pukul {{ detail.details[0].datetime | time }}
            </v-col>
          </v-row>

          <!-- status wali -->
          <v-row
            class="mb-2">
            <v-col
              cols="4"
              class="py-0">
              Status Wali
            </v-col>

            <v-col
              cols="8"
              class="py-0">
              : {{ detail.customer_wali_status !== '' ? detail.customer_wali_status : '-' }}
            </v-col>
          </v-row>

          <!-- wali -->
          <v-row
            class="mb-2">
            <v-col
              cols="4"
              class="py-0">
              Nama Wali
            </v-col>

            <v-col
              cols="8"
              class="py-0">
              : {{ detail.customer_wali_name !== '' ? detail.customer_wali_name : '-' }}
            </v-col>
          </v-row>

          <!-- pendamping -->
          <v-row
            class="mb-2">
            <v-col
              cols="4"
              class="py-0">
              Nama Pendamping
            </v-col>

            <v-col
              cols="8"
              class="py-0">
              : {{ detail.wali !== '' ? detail.wali : '-' }}
            </v-col>
          </v-row>

          <v-divider
            class="my-4"
            style="border-top: 1px dashed #bbb;" />

          <!-- alamat -->
          <div v-if="detail.type === 'home_care'">
            <v-row
              class="mb-2">
              <v-col
                cols="4"
                class="py-0">
                Alamat
              </v-col>

              <v-col
                cols="8"
                class="py-0">
                <div
                  class="d-flex align-start">
                  <div>
                    :
                  </div>

                  <div
                    class="ml-1">
                    {{ detail.address_full !== '' ? detail.address_full : '-' }}
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row
              class="mb-2">
              <v-col
                cols="4"
                class="py-0">
                Kecamatan
              </v-col>

              <v-col
                cols="8"
                class="py-0">
                : {{ detail.address_subdistrict_name !== '' ? detail.address_subdistrict_name : '-' }}
              </v-col>
            </v-row>

            <v-row
              class="mb-2">
              <v-col
                cols="4"
                class="py-0">
                Kabupaten
              </v-col>

              <v-col
                cols="8"
                class="py-0">
                : {{ detail.address_city_name !== '' ? detail.address_city_name : '-' }}
              </v-col>
            </v-row>

            <v-row
              class="mb-2">
              <v-col
                cols="4"
                class="py-0">
                Provinsi
              </v-col>

              <v-col
                cols="8"
                class="py-0">
                : {{ detail.address_province_name !== '' ? detail.address_province_name : '-' }}
              </v-col>
            </v-row>

            <v-row
              class="mb-2">
              <v-col
                cols="4"
                class="py-0">
                Keterangan Rumah
              </v-col>

              <v-col
                cols="8"
                class="py-0">
                <div
                  class="d-flex align-start">
                  <div>
                    :
                  </div>

                  <div
                    class="ml-1">
                    {{ detail.address_note !== '' ? detail.address_note : '-' }}
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row
              class="mb-2">
              <v-col
                cols="4"
                class="py-0">
                Gambar Rumah
              </v-col>

              <v-col
                cols="8"
                class="py-0">
                <div
                  class="d-flex align-start">
                  <div>
                    :
                  </div>

                  <div
                    class="ml-1">
                    <span
                      @click="dialog.gambar_rumah = true"
                      class="cursor-pointer"
                      style="color: #1976d3;">
                      Lihat disini
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row
              class="mb-2">
              <v-col
                cols="4"
                class="py-0">
                Link Map
              </v-col>

              <v-col
                cols="8"
                class="py-0">
                <div
                  class="d-flex align-start">
                  <div>
                    :
                  </div>

                  <div
                    class="text-truncate ml-1">
                    <a v-if="detail.address_link_map !== ''"
                      :href="detail.address_link_map"
                      target="_blank">
                      {{ detail.address_link_map }}
                    </a>

                    <span v-else>
                      -
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>

          <v-divider v-if="detail.type === 'home_care'"
            class="my-4"
            style="border-top: 1px dashed #bbb;" />

          <!-- transport -->
          <div v-if="detail.type === 'home_care'"
            class="my-4">
            <div class="body-2 black--text font-weight-bold mb-2">
              Transport
            </div>

            <div v-if="detail.transport_id !== '' && detail.transport_id !== '0'">
              <div
                class="d-flex align-center mb-1">
                <div>
                  {{ detail.transport_name }}
                </div>

                <v-spacer />

                <div>
                  {{ Number(detail.transport_cost) | price }}
                </div>
              </div>

              <div
                class="d-flex align-center">
                <div>
                  Qty ({{ detail.transport_qty }}x)
                </div>

                <v-spacer />

                <div
                  class="font-weight-bold black--text">
                  {{ Number(detail.transport_total_cost) | price }}
                </div>
              </div>
            </div>

            <div v-else>
              Transport belum ditentukan
            </div>
          </div>

          <!-- absen berangkat / sampai home care -->
          <div v-if="detail.type === 'home_care'">
            <v-divider
              class="my-4"
              style="border-top: 1px dashed #bbb;" />

            <div
              class="body-2 font-weight-bold black--text"
              :class="detail.absen_berangkat_datetime !== '' ? 'mb-2' : 'mb-4'">
              Absen
            </div>

            <div v-if="detail.absen_berangkat_datetime === '' || (detail.absen_berangkat_datetime !== '' && detail.absen_sampai_datetime === '')"
              class="d-flex align-center"
              :class="detail.absen_berangkat_datetime !== '' || detail.absen_sampai_datetime !== '' ? 'mb-6' : ''">
              <div
                class="line-text-second"
                style="width: 50%;">
                Silahkan melakukan absensi treatment
              </div>

              <v-spacer />

              <div class="font-weight-bold" v-if="data_array_status.status === 'belum selesai'">
                -
              </div>
              <div v-if="data_array_status.status === 'selesai'">
                <div>
                  <v-btn
                    @click="reset_absen_berangkat_sampai(); fetchAbsenKehadiran();"
                    fab
                    width="40"
                    height="40"
                    elevation="0">
                    <v-avatar
                      size="40">
                      <v-img
                        contain
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="detail.absen_berangkat_datetime === '' ? require('@/assets/icon/fingerprint_attendance_start.png') : require('@/assets/icon/fingerprint_attendance_stop.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>
                  </v-btn>
                </div>
              </div>
            </div>

            <div v-if="detail.absen_berangkat_datetime !== '' || detail.absen_sampai_datetime !== ''">
              <v-row v-if="detail.absen_berangkat_datetime !== ''"
                class="mb-4">
                <v-col
                  cols="4"
                  class="py-0 text-capitalize">
                  Berangkat
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  <div
                    class="d-flex align-start">
                    <div>
                      :
                    </div>

                    <div
                      class="ml-1">
                      <div
                        style="margin-bottom: 3px;">
                        {{ detail.absen_berangkat_datetime | date }} Pukul {{ detail.absen_berangkat_datetime | time }}
                      </div>

                      <a
                        :href="`http://maps.google.com/maps?q=${detail.absen_berangkat_latitude},${detail.absen_berangkat_longitude}`"
                        target="_blank"
                        class="d-flex align-center">
                        <v-icon size="18" color="blue">mdi-map-marker-radius</v-icon>

                        <span
                          class="ml-1">
                          Lihat lokasi
                        </span>
                      </a>
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row v-if="detail.absen_sampai_datetime !== ''"
                class="mb-4">
                <v-col
                  cols="4"
                  class="py-0 text-capitalize">
                  Sampai
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  <div
                    class="d-flex align-start">
                    <div>
                      :
                    </div>

                    <div
                      class="ml-1">
                      <div
                        style="margin-bottom: 3px;">
                        {{ detail.absen_sampai_datetime | date }} Pukul {{ detail.absen_sampai_datetime | time }}
                      </div>

                      <a
                        :href="`http://maps.google.com/maps?q=${detail.absen_sampai_latitude},${detail.absen_sampai_longitude}`"
                        target="_blank"
                        class="d-flex align-center">
                        <v-icon size="18" color="blue">mdi-map-marker-radius</v-icon>

                        <span
                          class="ml-1">
                          Lihat lokasi
                        </span>
                      </a>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>

          <v-divider v-if="detail.type === 'home_care'"
            class="my-4"
            style="border-top: 1px dashed #bbb;" />

          <!-- layanan -->
          <div
            class="d-flex align-center mb-1">
            <div
              class="body-2 black--text font-weight-bold">
              Layanan
            </div>

            <v-spacer />

            <div>
              <!-- <v-btn
                @click="sheet.item = true; fetchItem();"
                :disabled="status_required.absen || status_required.is_done_bool"
                small
                rounded
                :color="set_color"
                elevation="0"
                class="text-capitalize d-flex align-center white--text">
                <v-icon small>mdi-plus-circle-outline</v-icon>

                <span
                  class="ml-1">
                  Tambah
                </span>
              </v-btn> -->
            </div>
          </div>

          <v-row>
            <v-col
              v-for="(item, index) in detail.details"
              :key="index"
              cols="12">
              <v-card
                flat
                color="#FFFFFF40"
                class="pa-3 pt-2"
                style="border: 2px dashed #bbb; border-radius: 10px;">
                <v-list
                  flat
                  dense
                  three-line
                  color="transparent"
                  class="py-0">
                  <v-list-item
                    class="px-0">
                    <v-list-item-avatar
                      size="40"
                      tile
                      class="mt-3"
                      style="border: 2px solid #ffffff; border-radius: 5px !important;">
                      <v-img
                        contain
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="item.item_image_url !== '' ? item.item_image_url : require('@/assets/image/image_default.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <div
                        class="body-2 font-weight-bold text-capitalize">
                        <span
                          class="two-line">
                          {{ item.item_name }}
                        </span>
                      </div>

                      <div
                        class="pa-3 mt-2 mb-3"
                        style="border: 2px dashed #bbb; border-radius: 10px;">
                        <div
                          class="body-2 font-weight-light line-text-second black--text"
                          style="opacity: 70%;">
                          <div
                            class="d-flex align-center line-text-second">
                            <div v-if="item.type !== 'product'">
                              Durasi. <strong class="font-weight-bold black--text">{{ item.duration_minutes }} Menit</strong>
                            </div>

                            <v-spacer v-if="item.type !== 'product'" />

                            <div>
                              Qty. <strong class="font-weight-bold black--text">{{ item.qty }}x</strong>
                            </div>

                            <v-spacer />

                            <div>
                              {{ item.therapist_shift }}
                            </div>
                          </div>

                          <div>
                            Catatan Terapis: {{ item.note !== '' ? item.note : '-' }}
                          </div>
                        </div>
                      </div>

                      <div
                        class="d-flex align-center" v-if="item.type !== 'product'">
                        <div>
                          <div
                            class="body-2 mb-1"
                            style="opacity: 70%;">
                            Mulai
                          </div>

                          <div v-if="check_absen(detail.details, index)"
                            class="body-2 font-weight-bold black--text">
                            <div v-if="data_array_status.status === 'belum selesai'">
                              -
                            </div>
                            <div v-if="data_array_status.status === 'selesai'">
                              <span v-if="item.absen_mulai_datetime !== ''">
                                {{ item.absen_mulai_datetime | time }}
                              </span>

                              <span v-else>
                                <span v-if="detail.type === 'home_care' && detail.absen_sampai_datetime === ''">
                                  -
                                </span>

                                <v-btn v-if="(detail.type === 'home_care' && detail.absen_sampai_datetime !== '') || detail.type === 'on_site'"
                                  @click="form_absen_mulai_selesai_index = index; reset_absen_mulai_selesai(); fetchAbsenKehadiran();"
                                  fab
                                  :disabled="data_array_status.status === 'belum selesai'"
                                  width="40"
                                  height="40"
                                  elevation="0">
                                  <v-avatar
                                    size="40">
                                    <v-img
                                      contain
                                      width="100%"
                                      height="100%"
                                      alt="bidanvitacare"
                                      :src="require('@/assets/icon/fingerprint_attendance_start.png')"
                                      class="ma-auto">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular
                                            indeterminate
                                            :color="set_color">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>
                                    </v-img>
                                  </v-avatar>
                                </v-btn>
                              </span>
                            </div>
                          </div>

                          <div v-else>
                            -
                          </div>
                        </div>

                        <v-spacer />

                        <div>
                          <div
                            class="body-2 mb-1"
                            style="opacity: 70%;">
                            Selesai
                          </div>

                          <div v-if="check_absen(detail.details, index)"
                            class="body-2 font-weight-bold black--text">
                            <div v-if="data_array_status.status === 'belum selesai'">
                              -
                            </div>
                            <div v-if="data_array_status.status === 'selesai'">
                              <span v-if="item.absen_selesai_datetime !== ''">
                                {{ item.absen_selesai_datetime | time }}
                              </span>

                              <span v-else>
                                <span v-if="detail.type === 'home_care' && detail.absen_sampai_datetime === ''">
                                  -
                                </span>

                                <v-btn v-if="(detail.type === 'home_care' && detail.absen_sampai_datetime !== '') || detail.type === 'on_site'"
                                  @click="form_absen_mulai_selesai_index = index; reset_absen_mulai_selesai(); fetchAbsenKehadiran();"
                                  fab
                                  width="40"
                                  height="40"
                                  elevation="0">
                                  <v-avatar
                                    size="40">
                                    <v-img
                                      contain
                                      width="100%"
                                      height="100%"
                                      alt="bidanvitacare"
                                      :src="require('@/assets/icon/fingerprint_attendance_stop.png')"
                                      class="ma-auto">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular
                                            indeterminate
                                            :color="set_color">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>
                                    </v-img>
                                  </v-avatar>
                                </v-btn>
                              </span>
                            </div>
                          </div>

                          <div v-else>
                            -
                          </div>
                        </div>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </section>

    <!-- cek absen kehadiran -->
    <v-bottom-sheet
      v-model="sheet.error"
      inset>
      <v-sheet
        class="text-center"
        style="border-radius: 15px 15px 0px 0px; background: #f9dee2;">
        <div
          class="pa-4">
          <v-avatar
            size="50"
            style="border: 2px solid #ffffff;">
            <v-img
              width="100%"
              height="100%"
              alt="bidanvitacare"
              :src="user.image_url !== '' ? user.image_url : require('@/assets/image/member_default.jpg')"
              class="ma-auto">
              <template v-slot:placeholder>
                <v-row
                  class="fill-height"
                  align="center"
                  justify="center">
                  <v-progress-circular
                    size="20"
                    indeterminate
                    :color="set_color">
                  </v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-avatar>

          <div
            style="opacity: 70%;">
            <div
              class="font-weight-bold mt-2 mb-3"
              style="font-size: 18px;">
              Hai, {{ user.name }}
            </div>

            <div
              class="body-2 line-text-second">
              Silahkan melakukan absensi kehadiran terlebih dahulu sebelum melanjutkan proses kunjungan.
            </div>
          </div>
        </div>
      </v-sheet>
    </v-bottom-sheet>

    <!-- absen berangkat / sampai -->
    <v-bottom-sheet
      v-model="sheet.absen_berangkat_sampai"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        class="background">
        <div
          class="blur">
          <v-card-title
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon
              @click="sheet.absen_berangkat_sampai = false; $emit('load');"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span
              @click="sheet.absen_berangkat_sampai = false; $emit('load');"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Absensi
            </span>
          </v-card-title>

          <v-card-text
            class="pt-16">
            <v-row
              align="center"
              style="height: calc(100vh - 70px); overflow-y: auto;">
              <v-col
                cols="12"
                class="pa-0">
                <ValidationObserver
                  ref="form_absen_berangkat_sampai">
                  <v-form>
                    <div
                      class="text-center">
                      <div>
                        <div
                          class="title font-weight-bold mb-6">
                          Hai, {{ detail.therapist_name }}
                        </div>

                        <div
                          class="text-h4 font-weight-bold black--text">
                          {{ detail.details[0].datetime | time }}
                        </div>

                        <div
                          class="mb-8">
                          {{ detail.details[0].datetime | date }}
                        </div>

                        <div
                          class="mb-1">
                          <v-chip
                            small
                            class="caption white--text text-capitalize"
                            :color="set_color">
                            {{ detail.details[0].category_name !== '' ? detail.details[0].category_name : '-' }}
                          </v-chip>
                        </div>

                        <div
                          class="font-weight-bold">
                          {{ detail.details[0].item_name }}
                        </div>


                        <v-divider
                          class="my-2 mx-12"
                          style="border-top: 1px dashed #bbbbbb" />

                        <div
                          class="mb-16">
                          {{ detail.customer_name }} - {{ detail.customer_mobilephone | phone }}
                        </div>
                      </div>

                      <v-btn v-if="process.absen_kehadiran"
                        disabled
                        fab
                        width="175"
                        height="175"
                        elevation="0"
                        class="mt-2"
                        style="filter: grayscale(100%);">
                        <v-avatar
                          size="150">
                          <v-img
                            contain
                            width="100%"
                            height="100%"
                            alt="bidanvitacare"
                            :src="detail.absen_berangkat_datetime === '' ? require('@/assets/icon/fingerprint_attendance_start.png') : require('@/assets/icon/fingerprint_attendance_stop.png')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>
                      </v-btn>

                      <v-btn v-if="!process.absen_kehadiran"
                        @click="save_absen_berangkat_sampai()"
                        :loading="process.form"
                        :disabled="process.form"
                        fab
                        width="175"
                        height="175"
                        elevation="0"
                        class="mt-2">
                        <v-avatar
                          size="150">
                          <v-img
                            contain
                            width="100%"
                            height="100%"
                            alt="bidanvitacare"
                            :src="detail.absen_berangkat_datetime === '' ? require('@/assets/icon/fingerprint_attendance_start.png') : require('@/assets/icon/fingerprint_attendance_stop.png')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>
                      </v-btn>

                      <div>
                        <a
                          :href="`http://maps.google.com/maps?q=${form_absen_berangkat_sampai.latitude},${form_absen_berangkat_sampai.longitude}`"
                          target="_blank"
                          class="d-flex align-center justify-center mt-4 mb-2">
                          <v-icon size="18" color="blue">mdi-map-marker-radius</v-icon>

                          <span
                            class="ml-1">
                            Lokasi Anda Terkini
                          </span>
                        </a>

                        <div
                          class="body-1 font-weight-bold mt-16 mb-6">
                          Riwayat Absen
                        </div>

                        <v-row>
                          <v-col
                            cols="6">
                            <v-avatar
                              tile
                              size="40">
                              <v-img
                                contain
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="require('@/assets/icon/check_in.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <div v-if="detail.absen_berangkat_datetime !== ''"
                              class="mt-2">
                              <div
                                class="title font-weight-bold black--text">
                                {{ detail.absen_berangkat_datetime | time }}
                              </div>

                              <div>
                                Berangkat
                              </div>
                            </div>

                            <div v-else
                              class="mt-2">
                              <div
                                class="title font-weight-bold black--text">
                                -
                              </div>

                              <div>
                                Belum Berangkat
                              </div>
                            </div>
                          </v-col>

                          <v-col
                            cols="6">
                            <v-avatar
                              tile
                              size="40">
                              <v-img
                                contain
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="require('@/assets/icon/check_out.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <div v-if="detail.absen_sampai_datetime !== ''"
                              class="mt-2">
                              <div
                                class="title font-weight-bold black--text">
                                {{ detail.absen_sampai_datetime | time }}
                              </div>

                              <div>
                                Sampai
                              </div>
                            </div>

                            <div v-else
                              class="mt-2">
                              <div
                                class="title font-weight-bold black--text">
                                -
                              </div>

                              <div>
                                Belum Sampai
                              </div>
                            </div>
                          </v-col>
                        </v-row>

                        <div v-if="message.error.length > 0"
                          class="text-center my-4 d-flex align-center justify-center">
                          <div
                            class="error--text">
                            <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                            <span
                              class="ml-1">
                              {{ message.error }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-form>
                </ValidationObserver>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </v-card>
    </v-bottom-sheet>

    <!-- absen mulai / selesai -->
    <v-bottom-sheet
      v-model="sheet.absen_mulai_selesai"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        class="background">
        <div
          class="blur">
          <v-card-title
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon
              @click="sheet.absen_mulai_selesai = false; $emit('load');"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span
              @click="sheet.absen_mulai_selesai = false; $emit('load');"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Absensi
            </span>
          </v-card-title>

          <v-card-text
            class="pt-16">
            <v-row
              align="center"
              style="height: calc(100vh - 70px); overflow-y: auto;">
              <v-col
                cols="12"
                class="pa-0">
                <ValidationObserver
                  ref="form_absen_mulai_selesai">
                  <v-form>
                    <div v-if="form_absen_mulai_selesai_index !== ''"
                      class="text-center">
                      <div>
                        <div
                          class="title font-weight-bold mb-6">
                          Hai, {{ detail.therapist_name }}
                        </div>

                        <div
                          class="text-h4 font-weight-bold black--text">
                          {{ detail.details[form_absen_mulai_selesai_index].datetime | time }}
                        </div>

                        <div
                          class="mb-8">
                          {{ detail.details[form_absen_mulai_selesai_index].datetime | date }}
                        </div>

                        <div
                          class="mb-1">
                          <v-chip
                            small
                            class="caption white--text text-capitalize"
                            :color="set_color">
                            {{ detail.details[form_absen_mulai_selesai_index].category_name !== '' ? detail.details[form_absen_mulai_selesai_index].category_name : '-' }}
                          </v-chip>
                        </div>

                        <div
                          class="font-weight-bold">
                          {{ detail.details[form_absen_mulai_selesai_index].item_name }}
                        </div>


                        <v-divider
                          class="my-2 mx-12"
                          style="border-top: 1px dashed #bbbbbb" />

                        <div
                          class="mb-16">
                          {{ detail.customer_name }} - {{ detail.customer_mobilephone | phone }}
                        </div>
                      </div>

                      <v-btn v-if="process.absen_kehadiran"
                        disabled
                        fab
                        width="175"
                        height="175"
                        elevation="0"
                        class="mt-2"
                        style="filter: grayscale(100%);">
                        <v-avatar
                          size="150">
                          <v-img
                            contain
                            width="100%"
                            height="100%"
                            alt="bidanvitacare"
                            :src="detail.details[form_absen_mulai_selesai_index].absen_mulai_datetime === '' ? require('@/assets/icon/fingerprint_attendance_start.png') : require('@/assets/icon/fingerprint_attendance_stop.png')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>
                      </v-btn>

                      <v-btn v-if="!process.absen_kehadiran"
                        @click="save_absen_mulai_selesai()"
                        :loading="process.form"
                        :disabled="process.form"
                        fab
                        width="175"
                        height="175"
                        elevation="0"
                        class="mt-2">
                        <v-avatar
                          size="150">
                          <v-img
                            contain
                            width="100%"
                            height="100%"
                            alt="bidanvitacare"
                            :src="detail.details[form_absen_mulai_selesai_index].absen_mulai_datetime === '' ? require('@/assets/icon/fingerprint_attendance_start.png') : require('@/assets/icon/fingerprint_attendance_stop.png')"
                            class="ma-auto">
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height"
                                align="center"
                                justify="center">
                                <v-progress-circular
                                  indeterminate
                                  :color="set_color">
                                </v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-avatar>
                      </v-btn>

                      <div>
                        <a
                          :href="`http://maps.google.com/maps?q=${form_absen_mulai_selesai.latitude},${form_absen_mulai_selesai.longitude}`"
                          target="_blank"
                          class="d-flex align-center justify-center mt-4 mb-2">
                          <v-icon size="18" color="blue">mdi-map-marker-radius</v-icon>

                          <span
                            class="ml-1">
                            Lokasi Anda Terkini
                          </span>
                        </a>

                        <div
                          class="body-1 font-weight-bold mt-16 mb-6">
                          Riwayat Absen
                        </div>

                        <v-row>
                          <v-col
                            cols="6">
                            <v-avatar
                              tile
                              size="40">
                              <v-img
                                contain
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="require('@/assets/icon/check_in.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <div v-if="detail.details[form_absen_mulai_selesai_index].absen_mulai_datetime !== ''"
                              class="mt-2">
                              <div
                                class="title font-weight-bold black--text">
                                {{ detail.details[form_absen_mulai_selesai_index].absen_mulai_datetime | time }}
                              </div>

                              <div>
                                Mulai
                              </div>
                            </div>

                            <div v-else
                              class="mt-2">
                              <div
                                class="title font-weight-bold black--text">
                                -
                              </div>

                              <div>
                                Belum Mulai
                              </div>
                            </div>
                          </v-col>

                          <v-col
                            cols="6">
                            <v-avatar
                              tile
                              size="40">
                              <v-img
                                contain
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="require('@/assets/icon/check_out.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-avatar>

                            <div v-if="detail.details[form_absen_mulai_selesai_index].absen_selesai_datetime !== ''"
                              class="mt-2">
                              <div
                                class="title font-weight-bold black--text">
                                {{ detail.details[form_absen_mulai_selesai_index].absen_selesai_datetime | time }}
                              </div>

                              <div>
                                Sampai
                              </div>
                            </div>

                            <div v-else
                              class="mt-2">
                              <div
                                class="title font-weight-bold black--text">
                                -
                              </div>

                              <div>
                                Belum Sampai
                              </div>
                            </div>
                          </v-col>
                        </v-row>

                        <div v-if="message.error.length > 0"
                          class="text-center my-4 d-flex align-center justify-center">
                          <div
                            class="error--text">
                            <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                            <span
                              class="ml-1">
                              {{ message.error }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-form>
                </ValidationObserver>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </v-card>
    </v-bottom-sheet>



    <!-- riwayat -->
    <v-bottom-sheet
      v-model="sheet.history"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        class="background">
        <div
          class="blur">
          <v-card-title
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon
              @click="sheet.history = false"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span
              @click="sheet.history = false"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Riwayat {{ detail.customer_name }} - {{ detail.client_name}}
            </span>
          </v-card-title>

          <v-card-text
            class="pt-16 mt-2 px-0"
            style="height: calc(100vh - 5px); overflow: auto;">
            <v-card
              flat
              color="transparent">
              <v-card-text
                class="pt-2 pb-0">
                <v-text-field
                  v-model="search_history"
                  @click:clear="search_history = ''; fetchHistory();"
                  v-on:keyup.enter="fetchHistory()"
                  filled
                  rounded
                  dense
                  single-line
                  hide-details
                  :color="set_color"
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Cari data yang Anda butuhkan ?"
                  clearable>
                </v-text-field>
              </v-card-text>

              <v-card-text
                class="pb-0">
                <v-row>
                  <v-col
                    cols="12">
                    <v-skeleton-loader
                      v-for="(item, index) in process.load ? 4 : list_history"
                      :key="index"
                      :loading="process.load"
                      :class="index < list_history.length - 1 ? 'mb-4' : ''"
                      type="list-item-avatar-three-line">
                      <template>
                        <div>
                          <v-card v-if="!process.load && list_history.length > 0"
                            @click="sheet.history = false; sheet.detail_history = true; fetchDetailHistory(item.id);"
                            flat
                            color="transparent">
                            <div
                              class="d-flex align-center">
                              <div
                                class="font-weight-bold black--text">
                                <span v-if="item.datetime !== ''">
                                  {{ item.datetime | date }}
                                </span>

                                <span v-else>
                                  Tanggal belum ditentukan
                                </span>
                              </div>

                              <v-spacer />

                              <div v-if="item.datetime !== ''"
                                class="font-weight-bold">
                                {{ item.datetime | time }}
                              </div>
                            </div>

                            <div>
                              <v-divider
                                class="my-2"
                                style="border-top: 1px dashed #bbb;" />
                            </div>

                            <div
                              class="d-flex align-center mb-1">
                              <div
                                class="d-flex align-center">
                                <v-avatar
                                  size="22"
                                  style="border: 2px solid #ffffff;">
                                  <v-img
                                    width="100%"
                                    height="100%"
                                    alt="bidanvitacare"
                                    :src="item.customer_image_url !== '' ? item.customer_image_url : require('@/assets/image/member_default.jpg')"
                                    class="ma-auto">
                                    <template v-slot:placeholder>
                                      <v-row
                                        class="fill-height"
                                        align="center"
                                        justify="center">
                                        <v-progress-circular
                                          size="20"
                                          indeterminate
                                          :color="set_color">
                                        </v-progress-circular>
                                      </v-row>
                                    </template>
                                  </v-img>
                                </v-avatar>

                                <span
                                  class="ml-1">
                                  {{ item.customer_name }}
                                </span>
                              </div>

                              <v-spacer />

                              <div>
                                {{ item.store_name }}
                              </div>
                            </div>

                            <div>
                              <v-divider
                                class="my-2"
                                style="border-top: 1px dashed #bbb;" />
                            </div>

                            <div
                              class="mt-3 mb-2 pa-3 pt-2"
                              style="border: 2px dashed #bbb; border-radius: 10px;">
                              <v-chip
                                x-small
                                class="white--text"
                                :color="item.type === 'on_site' ? 'blue' : 'green'"
                                style="font-weight: 500;">
                                {{ item.type === 'on_site' ? 'On Site' : 'Home Care' }}
                              </v-chip>

                              <div
                                v-for="(sub_item, sub_index) in item.details"
                                :key="sub_index">
                                <div
                                  class="d-flex align-start my-1">
                                  <v-avatar
                                    tile
                                    size="22"
                                    style="border: 2px solid #ffffff;">
                                    <v-img
                                      width="100%"
                                      height="100%"
                                      alt="bidanvitacare"
                                      :src="sub_item.item_image_url !== '' ? sub_item.item_image_url : require('@/assets/image/image_default.png')"
                                      class="ma-auto">
                                      <template v-slot:placeholder>
                                        <v-row
                                          class="fill-height"
                                          align="center"
                                          justify="center">
                                          <v-progress-circular
                                            size="20"
                                            indeterminate
                                            :color="set_color">
                                          </v-progress-circular>
                                        </v-row>
                                      </template>
                                    </v-img>
                                  </v-avatar>

                                  <span
                                    class="ml-1">
                                    {{ sub_item.item_name }}
                                  </span>
                                </div>

                                <div
                                  class="d-flex align-center"
                                  style="margin-left: 26px;">
                                  <div>
                                    Durasi. <strong>{{ sub_item.duration_minutes }} Menit</strong>
                                  </div>

                                  <v-spacer />

                                  <div>
                                    Qty. <strong>{{ sub_item.qty }}x</strong>
                                  </div>

                                  <v-spacer />

                                  <div>
                                    {{ sub_item.therapist_shift !== '' ? sub_item.therapist_shift : '-' }}
                                  </div>
                                </div>

                                <v-divider v-if="sub_index < item.details.length - 1"
                                  class="my-2"
                                  style="border-top: 1px dashed #bbb;" />
                              </div>
                            </div>

                            <v-row>
                              <v-col
                                cols="12"
                                class="pt-1">
                                <v-card
                                  flat
                                  color="#FFFFFF40"
                                  class="pa-3"
                                  style="border: 2px dashed #bbb; border-radius: 10px;">
                                  <div
                                    class="d-flex align-center">
                                    <div>
                                      Klien
                                    </div>

                                    <v-spacer />

                                    <div v-if="item.client_name !== ''"
                                      class="d-flex align-center">
                                      <v-avatar
                                        size="22"
                                        style="border: 2px solid #ffffff;">
                                        <v-img
                                          width="100%"
                                          height="100%"
                                          alt="bidanvitacare"
                                          :src="item.client_image_url !== '' ? item.client_image_url : require('@/assets/image/member_default.jpg')"
                                          class="ma-auto">
                                          <template v-slot:placeholder>
                                            <v-row
                                              class="fill-height"
                                              align="center"
                                              justify="center">
                                              <v-progress-circular
                                                size="20"
                                                indeterminate
                                                :color="set_color">
                                              </v-progress-circular>
                                            </v-row>
                                          </template>
                                        </v-img>
                                      </v-avatar>

                                      <span
                                        class="ml-1">
                                        {{ item.client_name }}
                                      </span>
                                    </div>

                                    <div v-else>
                                      -
                                    </div>
                                  </div>

                                  <div v-if="item.address_link_map !== ''">
                                    <v-divider
                                      class="my-3"
                                      style="border-top: 1px dashed #bbb;" />
                                  </div>

                                  <div v-if="item.address_link_map !== ''"
                                    class="d-flex align-center">
                                    <div>
                                      Alamat
                                    </div>

                                    <v-spacer />

                                    <div>
                                      <a
                                        :href="item.address_link_map"
                                        target="_blank">
                                        Lihat Lokasi
                                      </a>
                                    </div>
                                  </div>

                                  <div v-if="item.type === 'home_care'">
                                    <v-divider
                                      class="my-3"
                                      style="border-top: 1px dashed #bbb;" />
                                  </div>

                                  <div v-if="item.type === 'home_care'">
                                    <div v-if="item.transport_id !== '' && item.transport_id !== '0'"
                                      class="d-flex align-center">
                                      <div>
                                        Transport ({{ item.transport_qty }} x {{ Number(item.transport_cost) | price }})
                                      </div>

                                      <v-spacer />

                                      <div class="font-weight-bold">
                                        {{ Number(item.transport_total_cost) | price }}
                                      </div>
                                    </div>

                                    <div v-else
                                      class="d-flex align-center">
                                      <div>
                                        Transport
                                      </div>

                                      <v-spacer />

                                      <div class="font-weight-bold">
                                        -
                                      </div>
                                    </div>
                                  </div>

                                  <div>
                                    <v-divider
                                      class="my-3"
                                      style="border-top: 1px dashed #bbb;" />
                                  </div>

                                  <div
                                    class="d-flex align-center">
                                    <div>
                                      Penugasan
                                    </div>

                                    <v-spacer />

                                    <div class="text-uppercase font-weight-bold">
                                      <!-- {{ !item.is_therapist_partner_bool ? 'Single' : 'Partner' }} -->
                                      {{ item.single_partner !== '' ? item.single_partner : '-' }}
                                    </div>
                                  </div>

                                  <div>
                                    <v-divider
                                      class="my-3"
                                      style="border-top: 1px dashed #bbb;" />
                                  </div>

                                  <div
                                    class="d-flex align-center">
                                    <div>
                                      Down Payment
                                    </div>

                                    <v-spacer />

                                    <div class="text-uppercase font-weight-bold">
                                      <span v-if="Number(item.dp) > 0">
                                        {{ Number(item.dp) | price }}
                                      </span>

                                      <span v-else>
                                        -
                                      </span>
                                    </div>
                                  </div>

                                  <div>
                                    <v-divider
                                      class="my-3"
                                      style="border-top: 1px dashed #bbb;" />
                                  </div>

                                  <div
                                    class="d-flex align-center">
                                    <div>
                                      Diinput Oleh
                                    </div>

                                    <v-spacer />

                                    <div>
                                      {{ item.create_user_name }}
                                    </div>
                                  </div>
                                </v-card>
                              </v-col>
                            </v-row>
                          </v-card>
                        </div>
                      </template>
                    </v-skeleton-loader>
                  </v-col>
                </v-row>

                <div v-if="!process.load && pagination_history.total_data > 10 && limit_history < pagination_history.total_data"
                  class="text-center my-4">
                  <v-btn
                    @click="limit_history += 10"
                    :loading="process.limit"
                    small
                    rounded
                    outlined
                    elevation="0"
                    :color="set_color"
                    class="text-capitalize"
                    :style="`color: ${set_color};`">
                    Lebih banyak
                  </v-btn>
                </div>

                <Empty v-if="!process.load && pagination_history.total_page < 1"
                  class="py-12"
                  margin="my-16"
                  size="125"
                  message="Riwayat Kunjungan" />
              </v-card-text>
            </v-card>
          </v-card-text>
        </div>
      </v-card>
    </v-bottom-sheet>

    <!-- detail riwayat -->
    <v-bottom-sheet
      v-model="sheet.detail_history"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        class="background">
        <div v-if="!process.load && Object.keys(detail_history).length > 0"
          class="blur">
          <v-card-title
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon
              @click="sheet.detail_history = false; detail_history = {};"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span
              @click="sheet.detail_history = false; detail_history = {};"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Jadwal {{ detail_history.customer_name }} - {{ detail_history.client_name}}
            </span>
          </v-card-title>

          <v-card-text
            class="mt-14">
            <div style="height: calc(100vh - 130px); overflow: auto;">
              <v-card
                flat
                color="transparent"
                class="mt-2"
                style="border-radius: 10px;">
                <v-list
                  flat
                  dense
                  color="transparent">
                  <v-list-item
                    class="px-0">
                    <v-list-item-avatar
                      size="40"
                      style="border: 2px solid #ffffff; overflow: unset;">
                      <v-img
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="detail_history.customer_image_url !== '' ? detail_history.customer_image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        class="body-2 font-weight-bold text-capitalize mb-2">
                        {{ detail_history.customer_name }}
                      </v-list-item-title>

                      <v-list-item-subtitle
                        class="body-2 font-weight-light black--text">
                        {{ detail_history.customer_mobilephone }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>

              <v-divider
                class="mt-1 mb-4"
                style="border-top: 1px dashed #bbb;" />

              <!-- tipe & umur -->
              <div
                class="d-flex align-center">
                <div>
                  <div
                    class="body-2 mb-1">
                    Tipe
                  </div>

                  <div
                    class="body-2 font-weight-bold black--text">
                    {{ detail_history.type === 'on_site' ? 'On Site' : 'Home Care' }}
                  </div>
                </div>

                <v-spacer />

                <div>
                  <div
                    class="body-2 mb-1">
                    Umur
                  </div>

                  <div
                    class="body-2 font-weight-bold black--text">
                    {{ detail_history.antropometri.age !== '' ? detail_history.antropometri.age : '-' }}
                  </div>
                </div>
              </div>

              <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <!-- bb / tb / lila / lk / tensi -->
              <div
                class="d-flex align-center">
                <div>
                  <div
                    class="body-2 mb-1">
                    BB
                  </div>

                  <div
                    class="body-2 black--text font-weight-bold">
                    {{ detail_history.antropometri.bb }} Kg
                  </div>
                </div>

                <v-spacer v-if="detail_history.client_type === 'bayi' || detail_history.client_type === 'anak'" />

                <div v-if="detail_history.client_type === 'bayi' || detail_history.client_type === 'anak'">
                  <div
                    class="body-2 mb-1">
                    TB
                  </div>

                  <div
                    class="body-2 black--text font-weight-bold">
                    {{ detail_history.antropometri.tb }} Cm
                  </div>
                </div>

                <v-spacer v-if="detail_history.client_type === 'bayi' || detail_history.client_type === 'anak'" />

                <div v-if="detail_history.client_type === 'bayi' || detail_history.client_type === 'anak'">
                  <div
                    class="body-2 mb-1">
                    LILA
                  </div>

                  <div
                    class="body-2 black--text font-weight-bold">
                    {{ detail_history.antropometri.lila }} Cm
                  </div>
                </div>

                <v-spacer v-if="detail_history.client_type === 'bayi' || detail_history.client_type === 'anak'" />

                <div v-if="detail_history.client_type === 'bayi' || detail_history.client_type === 'anak'">
                  <div
                    class="body-2 mb-1">
                    LK
                  </div>

                  <div
                    class="body-2 black--text font-weight-bold">
                    {{ detail_history.antropometri.lk }} Cm
                  </div>
                </div>

                <v-spacer v-if="detail_history.client_type !== 'bayi' && detail_history.client_type !== 'anak'" />

                <div v-if="detail_history.client_type !== 'bayi' && detail_history.client_type !== 'anak'">
                  <div
                    class="body-2 mb-1">
                    Tensi
                  </div>

                  <div
                    class="body-2 black--text font-weight-bold">
                    {{ detail_history.antropometri.tensi }} mmHg
                  </div>
                </div>
              </div>

              <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <!-- klien -->
              <div
                class="d-flex align-center">
                <div
                  class="body-2">
                  Klien
                </div>

                <v-spacer />

                <div>
                  <div
                    class="d-flex align-center">
                    <v-avatar
                      size="24"
                      class="mt-0"
                      style="border: 2px solid #ffffff;">
                      <v-img
                        width="100%"
                        height="100%"
                        alt="bidanvitacare"
                        :src="detail_history.client_image_url !== '' ? detail_history.client_image_url : require('@/assets/image/member_default.jpg')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              size="20"
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-avatar>

                    <span
                      class="caption black--text font-weight-bold one-line text-capitalize ml-1">
                      {{ detail_history.client_name }}
                    </span>
                  </div>
                </div>
              </div>

              <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <!-- alat -->
              <div
                class="mb-4">
                <div
                  class="body-2 font-weight-bold black--text mb-2">
                  Alat yang harus dibawa terapis
                </div>

                <div
                  class="body-2 text-capitalize">
                  <ul
                    v-for="(item, index) in detail.details" :key="index"
                    style="position: relative; left: -10px;">
                    <li
                      :class="index < detail.details.length - 1 ? 'mb-3' : ''"  v-if="item.type !== 'product'">
                      <div
                        class="mb-1">
                        {{ item.item_name }}
                      </div>

                      <ul v-if="item.material_json_array.length > 0"
                        style="position: relative; left: -10px;">
                        <li
                          v-for="(sub_item, sub_index) in item.material_json_array"
                          :key="sub_index"
                          :class="sub_index < item.material_json_array.length - 1 ? 'mb-1' : ''"
                          v-html="sub_item.name">
                        </li>
                      </ul>

                      <div v-else>
                        -
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div>
                <div
                  class="body-2 font-weight-bold black--text mb-2">
                  Alat yang harus dipersiapkan customer
                </div>

                <div
                  class="body-2 text-capitalize">
                  <ul
                    v-for="(item, index) in detail.details" :key="index"
                    style="position: relative; left: -10px;">
                    <li
                      :class="index < detail.details.length - 1 ? 'mb-3' : ''" v-if="item.type !== 'product'">
                      <div
                        class="mb-1">
                        {{ item.item_name }}
                      </div>

                      <ul v-if="item.customer_prepare_json_array.length > 0"
                        style="position: relative; left: -10px;">
                        <li
                          v-for="(sub_item, sub_index) in item.customer_prepare_json_array"
                          :key="sub_index"
                          :class="sub_index < item.customer_prepare_json_array.length - 1 ? 'mb-1' : ''"
                          v-html="sub_item.value">
                        </li>
                      </ul>

                      <div v-else>
                        -
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <!-- tanggal dan jam -->
              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Tanggal & Waktu Kunjungan
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  : {{ detail_history.details[0].datetime | date }} Pukul {{ detail_history.details[0].datetime | time }}
                </v-col>
              </v-row>

              <!-- status wali -->
              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Status Wali
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  : {{ detail_history.customer_wali_status !== '' ? detail_history.customer_wali_status : '-' }}
                </v-col>
              </v-row>

              <!-- wali -->
              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Nama Wali
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  : {{ detail_history.customer_wali_name !== '' ? detail_history.customer_wali_name : '-' }}
                </v-col>
              </v-row>

              <!-- pendamping -->
              <v-row
                class="mb-2">
                <v-col
                  cols="4"
                  class="py-0">
                  Nama Pendamping
                </v-col>

                <v-col
                  cols="8"
                  class="py-0">
                  : {{ detail_history.wali !== '' ? detail_history.wali : '-' }}
                </v-col>
              </v-row>

              <v-divider
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <!-- alamat -->
              <div v-if="detail_history.type === 'home_care'">
                <v-row
                  class="mb-2">
                  <v-col
                    cols="4"
                    class="py-0">
                    Alamat
                  </v-col>

                  <v-col
                    cols="8"
                    class="py-0">
                    <div
                      class="d-flex align-start">
                      <div>
                        :
                      </div>

                      <div
                        class="ml-1">
                        {{ detail_history.address_full !== '' ? detail_history.address_full : '-' }}
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-row
                  class="mb-2">
                  <v-col
                    cols="4"
                    class="py-0">
                    Kecamatan
                  </v-col>

                  <v-col
                    cols="8"
                    class="py-0">
                    : {{ detail_history.address_subdistrict_name !== '' ? detail_history.address_subdistrict_name : '-' }}
                  </v-col>
                </v-row>

                <v-row
                  class="mb-2">
                  <v-col
                    cols="4"
                    class="py-0">
                    Kabupaten
                  </v-col>

                  <v-col
                    cols="8"
                    class="py-0">
                    : {{ detail_history.address_city_name !== '' ? detail_history.address_city_name : '-' }}
                  </v-col>
                </v-row>

                <v-row
                  class="mb-2">
                  <v-col
                    cols="4"
                    class="py-0">
                    Provinsi
                  </v-col>

                  <v-col
                    cols="8"
                    class="py-0">
                    : {{ detail_history.address_province_name !== '' ? detail_history.address_province_name : '-' }}
                  </v-col>
                </v-row>

                <v-row
                  class="mb-2">
                  <v-col
                    cols="4"
                    class="py-0">
                    Keterangan Rumah
                  </v-col>

                  <v-col
                    cols="8"
                    class="py-0">
                    <div
                      class="d-flex align-start">
                      <div>
                        :
                      </div>

                      <div
                        class="ml-1">
                        {{ detail_history.address_note !== '' ? detail_history.address_note : '-' }}
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-row
                  class="mb-2">
                  <v-col
                    cols="4"
                    class="py-0">
                    Gambar Rumah
                  </v-col>

                  <v-col
                    cols="8"
                    class="py-0">
                    <div
                      class="d-flex align-start">
                      <div>
                        :
                      </div>

                      <div
                        class="ml-1">
                        <span
                          @click="dialog.gambar_rumah = true"
                          class="cursor-pointer"
                          style="color: #1976d3;">
                          Lihat disini
                        </span>
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-row
                  class="mb-2">
                  <v-col
                    cols="4"
                    class="py-0">
                    Link Map
                  </v-col>

                  <v-col
                    cols="8"
                    class="py-0">
                    <div
                      class="d-flex align-start">
                      <div>
                        :
                      </div>

                      <div
                        class="text-truncate ml-1">
                        <a v-if="detail_history.address_link_map !== ''"
                          :href="detail_history.address_link_map"
                          target="_blank">
                          {{ detail_history.address_link_map }}
                        </a>

                        <span v-else>
                          -
                        </span>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>

              <v-divider v-if="detail_history.type === 'home_care'"
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <!-- kpsp -->
              <div v-if="detail.kpsp.length > 0">
                <div
                  class="mb-2">
                  <div
                    class="body-2 black--text font-weight-bold mb-1">
                    KPSP
                  </div>

                  <div>
                    Berikut ini adalah data kpsp.
                  </div>
                </div>

                <v-list
                  v-for="(item, index) in detail.kpsp"
                  :key="index"
                  flat
                  dense
                  two-line
                  color="#FFFFFF40"
                  class="border-radius pa-0">
                  <v-list-item
                    class="pa-0">
                    <v-list-item-content>
                      <v-list-item-title
                        class="body-2 font-weight-bold text-capitalize">
                        {{ item.kpsp_type }} - {{ item.kpsp_age_month }} Bulan
                      </v-list-item-title>

                      <v-list-item-subtitle
                        class="body-2 font-weight-light line-text-second black--text"
                        style="opacity: 70%;">
                        {{ item.kpsp_question }}
                      </v-list-item-subtitle>

                      <v-list-item-subtitle
                        class="body-2 font-weight-bold">
                        Jawaban:

                        <span
                          :class="item.kpsp_answer === 'YA' ? 'green--text' : item.kpsp_answer === 'TIDAK' ? 'red--text' : ''">
                          {{ item.kpsp_answer !== '' ? item.kpsp_answer : '-' }}
                        </span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>

              <v-divider v-if="detail.kpsp.length > 0"
                class="my-4"
                style="border-top: 1px dashed #bbb;" />

              <!-- laporan -->
              <div
                class="mb-4">
                <div
                  class="body-2 black--text font-weight-bold mb-1">
                  Laporan
                </div>

                <div>
                  Berikut ini adalah data laporan.
                </div>
              </div>

              <div>
                <div
                  class="mb-3">
                  <div
                    class="font-weight-bold black--text mb-1">
                    Catatan Kejadian
                  </div>

                  <div>
                    {{ detail_history.laporan_catatan_kejadian !== '' && detail_history.laporan_catatan_kejadian !== null ? detail_history.laporan_catatan_kejadian : '-' }}
                  </div>
                </div>

                <div
                  class="mb-3">
                  <div
                    class="font-weight-bold black--text mb-1">
                    Perihal Kesehatan Ibu & Anak yang ditanyakan
                  </div>

                  <div>
                    {{ detail_history.laporan_perihal_kesehatan !== '' && detail_history.laporan_perihal_kesehatan !== null ? detail_history.laporan_perihal_kesehatan : '-' }}
                  </div>
                </div>

                <div
                  class="mb-3">
                  <div
                    class="font-weight-bold black--text mb-1">
                    Pertanyaan kepo tentang manajemen
                  </div>

                  <div>
                    {{ detail_history.laporan_pertanyaan_kepo !== '' && detail_history.laporan_pertanyaan_kepo !== null ? detail_history.laporan_pertanyaan_kepo : '-' }}
                  </div>
                </div>

                <div
                  class="mb-3">
                  <div
                    class="font-weight-bold black--text mb-1">
                    Kesalahan yang dilakukan Terapis
                  </div>

                  <div>
                    {{ detail_history.laporan_kesalahan_therapist !== '' && detail_history.laporan_kesalahan_therapist !== null ? detail_history.laporan_kesalahan_therapist : '-' }}
                  </div>
                </div>

                <div
                  class="mb-3">
                  <div
                    class="font-weight-bold black--text mb-1">
                    Komplain Klien secara langsung
                  </div>

                  <div>
                    {{ detail_history.laporan_komplain !== '' && detail_history.laporan_komplain !== null ? detail_history.laporan_komplain : '-' }}
                  </div>
                </div>

                <div
                  class="mb-3">
                  <div
                    class="font-weight-bold black--text mb-1">
                    Masukkan untuk manajemen bidanvitacare / kendala yang dihadapi saat homecare atau onsite
                  </div>

                  <div>
                    {{ detail_history.laporan_masukan !== '' && detail_history.laporan_masukan !== null ? detail_history.laporan_masukan : '-' }}
                  </div>
                </div>

                <div>
                  <div
                    class="font-weight-bold black--text mb-1">
                    Promosi
                  </div>

                  <div>
                    {{ detail_history.laporan_promosi !== '' && detail_history.laporan_promosi !== null ? detail_history.laporan_promosi : '-' }}
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>

          <v-card
            flat
            color="#FFFFFF"
            min-height="50"
            style="border-radius: 15px 15px 0px 0px;
                  position: sticky;
                  bottom: 0;
                  width: 460px;
                  z-index: 1;">
            <v-card-text
              class="pa-4">
              <v-row>
                <v-col
                  cols="6"
                  class="py-0">
                  <v-btn
                    @click="sheet.attachment_history = true"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                    <div
                      class="btn_grey_in d-flex align-center justify-center">
                      <div>
                        <v-img
                          contain
                          width="20"
                          height="20"
                          alt="bidanvitacare"
                          :src="require('@/assets/icon/attachment.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>

                      <div
                        class="pink--text ml-2">
                        Lampiran
                      </div>
                    </div>
                  </v-btn>
                </v-col>

                <v-col
                  cols="6"
                  class="py-0">
                  <v-btn
                    @click="sheet.history = true; fetchHistory();"
                    block
                    large
                    rounded
                    elevation="0"
                    class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                    <div
                      class="btn_grey_in d-flex align-center justify-center">
                      <div>
                        <v-img
                          contain
                          width="20"
                          height="20"
                          alt="bidanvitacare"
                          :src="require('@/assets/icon/calendar_pending.png')"
                          class="ma-auto">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height"
                              align="center"
                              justify="center">
                              <v-progress-circular
                                size="20"
                                indeterminate
                                :color="set_color">
                              </v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </div>

                      <div
                        class="blue--text ml-2">
                        Riwayat
                      </div>
                    </div>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-bottom-sheet>

    <!-- lampiran riwayat -->
    <v-bottom-sheet
      v-model="sheet.attachment_history"
      hide-overlay
      persistent
      fullscreen
      scrollable>
      <v-card
        flat
        class="background">
        <div v-if="Object.keys(detail_history).length > 0"
          class="blur">
          <v-card-title
            class="pa-4"
            style="border-radius: 0px 0px 15px 15px;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  background: #f9dee2;">
            <v-icon
              @click="sheet.attachment_history = false;"
              :color="set_color"
              class="cursor-pointer ">
              mdi-arrow-left-thick
            </v-icon>

            <span
              @click="sheet.attachment_history = false;"
              class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
              :style="`color: ${set_color};`"
              style="width: 90%;">
              Lampiran {{ detail_history.customer_name }}
            </span>
          </v-card-title>

          <v-card-text
            class="pb-0 pt-16"
            style="height: calc(100vh - 8px); overflow: auto;">
            <v-row>
              <v-col
                v-for="(item, index) in detail_history.dokumentasi_json_array"
                :key="index"
                cols="6">
                <v-card
                  v-if="detail_history.dokumentasi_json_array.length > 0"
                  flat
                  color="transparent"
                  height="200">
                  <v-img
                    contain
                    width="100%"
                    height="100%"
                    alt="bidanvitacare"
                    :src="item !== '' ? item : require('@/assets/image/image_default.png')"
                    class="ma-auto"
                    style="border: 5px solid #0000000d;
                          border-radius: 10px;">
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center">
                        <v-progress-circular
                          size="20"
                          indeterminate
                          :color="set_color">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>

            <Empty v-if="detail_history.dokumentasi_json_array.length < 1"
              class="py-6"
              margin="my-16"
              size="125"
              message="Lampiran" />
          </v-card-text>
        </div>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
  export default {
    props: {
      detail: {
        type: Object,
        default: {}
      },
      status_required: {
        type: Object,
        default: {}
      },
      data_array_status: {
        type: Object,
        default: {}
      },
    },
    data () {
      return {
        dialog: {
          gambar_rumah: false,
          permission: false
        },

        sheet: {
          absen_berangkat_sampai: false,
          absen_mulai_selesai: false,
          error: false,

          history: false,
          detail_history: false,
          attachment_history: false
        },

        selected: {
          gambar_rumah_index: ''
        },

        search_history: '',

        detail_history: {},

        list_history: [],
        pagination_history: {},
        limit_history: 10,

        absen_kehadiran: false,

        form_absen_berangkat_sampai: {
          visit_id: '',
          latitude: '',
          longitude: ''
        },

        form_absen_mulai_selesai_index: '',

        form_absen_mulai_selesai: {
          detail_id: '',
          latitude: '',
          longitude: ''
        },

        process: {
          load: false,

          absen_kehadiran: false,

          form: false
        },

        message: {
          success: '',
          error: ''
        }
      }
    },
    components: {
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'limit_history': function() {
        this.process.limit = true

        this.fetchHistory('limit')
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {

    },
    methods: {
      async fetchAbsenKehadiran () {
        this.process.absen_kehadiran = true

        await this.$axios.$get(`${process.env.API}therapist/absen/detail`)
        .then((response) => {

          this.process.absen_kehadiran = false

          if (response.status === 200) {
            this.absen_kehadiran = Object.keys(response.results.data).length > 0 ? true : false
          }
        })
      },

      reset_absen_berangkat_sampai () {
        if (this.$refs.form_absen_berangkat_sampai) this.$refs.form_absen_berangkat_sampai.reset()

        let self = this

        navigator.permissions.query({ name: 'geolocation' })
        .then(function(result) {
          if (result.state !== 'denied') {
            navigator.geolocation.getCurrentPosition(position => {
              if (self.detail.type === 'home_care') {
                self.form_absen_berangkat_sampai = {
                  visit_id: self.detail.id,
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude
                }
              }
            })
          } else {
            self.dialog.permission = true
          }
        })

        this.process.form = false

        this.message.error = ''

        this.sheet.absen_berangkat_sampai = true
      },

      async save_absen_berangkat_sampai () {
        this.message.error = ''

        if (!this.absen_kehadiran) {
          this.sheet.error = true
        }

        else {
          const isValid = await this.$refs.form_absen_berangkat_sampai.validate()

          if (isValid) {
            this.process.form = true

            let url = this.detail.type === 'home_care' && this.detail.absen_berangkat_datetime === '' ? 'therapist/schedule_v3/absen/berangkat' : 'therapist/schedule_v3/absen/sampai'

            // SAVE TO REST API
            await this.$axios.$post(`${process.env.API}${url}`, this.form_absen_berangkat_sampai)
            .then((response) => {

              this.process.form = false

              if (response.status === 200) {

                this.sheet.absen_berangkat_sampai = false

                this.$emit('load')
              } else {
                this.message.error = response.message
              }
            })

          } else {
            // AUTO FOCUS ON TEXT-FIELD
            setTimeout(() => {
              const errors = Object.entries(this.$refs.form_absen_berangkat_sampai.errors)
                .map(([key, value]) => ({ key, value }))
                .filter(error => error['value'].length)
              this.$refs.form_absen_berangkat_sampai.refs[errors[0]['key']].$el.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              })
            }, 10)
          }
        }
      },

      reset_absen_mulai_selesai () {
        if (this.$refs.form_absen_mulai_selesai) this.$refs.form_absen_mulai_selesai.reset()

        let self = this

        navigator.permissions.query({ name: 'geolocation' })
        .then(function(result) {
          if (result.state !== 'denied') {
            navigator.geolocation.getCurrentPosition(position => {
              if ((self.detail.type === 'home_care' && self.detail.absen_sampai_datetime !== '') || self.detail.type === 'on_site') {
                self.form_absen_mulai_selesai = {
                  detail_id: self.detail.details[self.form_absen_mulai_selesai_index].id,
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude
                }
              }
            })
          } else {
            self.dialog.permission = true
          }
        })

        this.process.form = false

        this.message.error = ''

        this.sheet.absen_mulai_selesai = true
      },

      async save_absen_mulai_selesai () {
        this.message.error = ''

        if (!this.absen_kehadiran) {
          this.sheet.error = true
        }

        else {
          const isValid = await this.$refs.form_absen_mulai_selesai.validate()

          if (isValid) {
            this.process.form = true

            let url = '',
            detail_id_product_arr = [],
            forms = {}


            if ((this.detail.type === 'home_care' && this.detail.absen_sampai_datetime !== '') || this.detail.type === 'on_site') {

              if (this.detail.details[this.form_absen_mulai_selesai_index].absen_mulai_datetime === '') {
                url = 'therapist/schedule_v3/layanan/mulai'
                this.play_start()
              } else {
                url = 'therapist/schedule_v3/layanan/selesai'
                this.play_end()
              }
            }

            this.detail.details.map((item, index) => {
              if (item.type === 'product') {
                // if (item.absen_selesai_datetime === '') {
                //   cek_status = true
                //   if (this.detail.details[this.form_absen_mulai_selesai_index].absen_mulai_datetime === '') {
                //     this.updateDataProduct(item.id, 'mulai')
                //   } else {
                //     this.updateDataProduct(item.id, 'selesai')
                //   }
                // }
                detail_id_product_arr.push(item.id)
              }
            })

            forms = url === 'therapist/schedule_v3/layanan/mulai' ?
              {
                detail_id: this.form_absen_mulai_selesai.detail_id,
                latitude: this.form_absen_mulai_selesai.latitude,
                longitude: this.form_absen_mulai_selesai.longitude

              } : {
                detail_id: this.form_absen_mulai_selesai.detail_id,
                latitude: this.form_absen_mulai_selesai.latitude,
                longitude: this.form_absen_mulai_selesai.longitude,
                detail_id_product_arr: detail_id_product_arr
              }

            // SAVE TO REST API
            await this.$axios.$post(`${process.env.API}${url}`, forms)
            .then((response) => {

              this.process.form = false

              if (response.status === 200) {
                let cek_status = false

                // this.detail.details.map((item, index) => {
                //   if (item.type === 'product') {
                //     if (item.absen_selesai_datetime === '') {
                //       cek_status = true
                //       if (this.detail.details[this.form_absen_mulai_selesai_index].absen_mulai_datetime === '') {
                //         this.updateDataProduct(item.id, 'mulai')
                //       } else {
                //         this.updateDataProduct(item.id, 'selesai')
                //       }
                //     }
                //   }
                // })

                if (!cek_status) {
                  this.sheet.absen_mulai_selesai = false

                  this.$emit('load')
                }
              } else {
                this.message.error = response.message
              }
            })

          } else {
            // AUTO FOCUS ON TEXT-FIELD
            setTimeout(() => {
              const errors = Object.entries(this.$refs.form_absen_mulai_selesai.errors)
                .map(([key, value]) => ({ key, value }))
                .filter(error => error['value'].length)
              this.$refs.form_absen_mulai_selesai.refs[errors[0]['key']].$el.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              })
            }, 10)
          }
        }
      },

      async updateDataProduct (id, type) {
        let params = {
          detail_id: id,
          latitude: this.form_absen_mulai_selesai.latitude,
          longitude: this.form_absen_mulai_selesai.longitude
        }

        await this.$axios.$post(`${process.env.API}therapist/schedule_v3/layanan/${type}`, params )
        .then((response) => {
          if (response.status === 200) {
            let cek_status = false
            if (type === 'mulai') {
              this.detail.details.map((item, index) => {
                if (item.type === 'product') {
                  if (item.absen_mulai_datetime === '') {
                    if (id === item.id) {
                      item.absen_mulai_datetime = new Date().toISOString()
                    }
                  } else {
                  }
                }
              })
            } else {
              this.detail.details.map((item, index) => {
                if (item.type === 'product') {
                  if (item.absen_selesai_datetime === '') {
                    if (id === item.id) {
                      item.absen_selesai_datetime = new Date().toISOString()
                    }
                  } else {
                  }
                }
              })
            }
            this.detail.details.map((item, index) => {
              if (item.type === 'product') {
                if (type === 'mulai') {
                  if (item.absen_mulai_datetime === '') {
                    cek_status = true
                  } else {
                    cek_status = false
                  }
                } else {
                  if (item.absen_selesai_datetime === '') {
                    cek_status = true
                  } else {
                    cek_status = false
                  }
                }
              }
            })

            if (!cek_status) {

              this.sheet.absen_mulai_selesai = false

              this.$emit('load')
            }
          }
        })
      },


      play_start () {
        let audio = new Audio('https://cdndev.amanata.co/2023/07/file/awalan-1688230927.mp3')

        audio.play()
      },
      play_end () {
        let audio = new Audio('https://cdndev.amanata.co/2023/07/file/akhiran+revisi-1688230981.mp3')

        audio.play()
      },

      // riwayat
      async fetchHistory (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search_history,
          limit: this.limit_history,

          client_id: this.detail.client_id
        }

        await this.$axios.$get(`${process.env.API}therapist/schedule_v3/data_visit_history`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list_history = response.results.data
            this.pagination_history = response.results.pagination
          }
        })
      },

      async fetchDetailHistory (id) {
        this.process.load = true

        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}therapist/schedule_v3/data_visit_detail`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.detail_history = response.results.data
          }
        })
      },

      // validasi absen
      check_absen (data, index) {
        let data_array = [],
            index_new = 0,
            id = data.id
        data.map((item) => {
          if (item.type !== 'product') {
            data_array.push(item)
          }
        })
        data_array.map((item, i) => {
          if (item.id === data[index].id) {
            index_new = i
          }
        })
        if (index > 0) {
          if (data_array[index_new - 1].absen_selesai_datetime === '') {
            return false
          } else {
            return true
          }
        } else {
          return true
        }
      }
    }
  }
</script>
