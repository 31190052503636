<template>
  <div class="fill-height pt-16">
    <v-layout wrap>
      <v-flex>
        <v-dialog v-model="dialog.deletes"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="150"
                height="150"
                alt="bidanvitacare"
                :src="require('@/assets/icon/hapus_info.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  Apakah Anda yakin menghapus data yang Anda pilih?
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="deletes_promo()"
                :loading="process.form"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Saya yakin
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.deletes = false"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog.deletes_transport"
          persistent>
          <v-card
            class="border-radius pa-2">
            <v-card-text>
              <v-img
                width="150"
                height="150"
                alt="bidanvitacare"
                :src="require('@/assets/icon/hapus_info.png')"
                class="ma-auto mt-4 mb-3">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center">
                    <v-progress-circular
                      size="20"
                      indeterminate
                      :color="set_color">
                    </v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                class="body-2 text-center line-text-second">
                <div>
                  Apakah Anda yakin menghapus data transport yang Anda pilih?
                </div>
              </div>
            </v-card-text>

            <v-divider
              class="mx-4 mb-3 mt-0"/>

            <v-card-actions>
              <v-spacer/>

              <v-btn
                @click="deletes_transport()"
                :loading="process.form"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_save">
                <div
                  class="btn_save_in d-flex align-center justify-center">
                  <v-icon
                    size="20">
                    mdi-check-bold
                  </v-icon>

                  <span
                    class="ml-1">
                    Ya, Saya yakin
                  </span>
                </div>
              </v-btn>

              <v-btn
                @click="dialog.deletes_transport = false"
                :disabled="process.form"
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                <div
                  class="btn_cancel_in">
                  <v-icon
                    size="20">
                    mdi-close-thick
                  </v-icon>

                  <span
                    class="ml-1">
                    Batal
                  </span>
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <section>
          <v-card
            flat
            color="transparent">
            <v-card-text>
              <v-select
                filled
                rounded
                dense
                :color="set_color"
                placeholder="Pilih Konfigurasi"

                :items="menu"
                item-text="text"
                item-value="value"
                :item-color="set_color"

                hide-details

                v-model="selected.menu">
              </v-select>
            </v-card-text>
          </v-card>

          <v-card v-if="selected.menu === 'transport'"
            flat
            color="transparent">
            <v-card-text
              class="pt-1">
              <v-text-field
                v-model="search"
                @click:clear="search = ''; fetch();"
                v-on:keyup.enter="fetch()"
                filled
                rounded
                dense
                single-line
                hide-details
                :color="set_color"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>

              <v-btn
                @click="sheet.form = true; reset();"
                block
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_auth mt-4"
                :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                <div
                  class="btn_auth_in d-flex align-center justify-center"
                  :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                  <div>
                    <v-img
                      contain
                      width="20"
                      height="20"
                      alt="bidanvitacare"
                      :src="require('@/assets/icon/add_plus.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            size="20"
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>

                  <div
                    class="ml-2">
                    Tambah Transport
                  </div>
                </div>
              </v-btn>
            </v-card-text>

            <v-card-text
              class="pb-0">
              <div
                class="body-2 font-weight-bold black--text d-flex align-center mb-2">
                <div>
                  Transport
                </div>

                <v-spacer />

                <div>
                  <!-- <v-icon>mdi-filter-variant</v-icon> -->
                </div>
              </div>

              <v-row>
                <v-col>
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 5 : list"
                    :key="index"
                    :loading="process.load"
                    :class="index < list.length - 1 ? 'mb-4' : ''"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-list
                          v-if="!process.load && list.length > 0"
                          flat
                          dense
                          color="#FFFFFF40"
                          class="border-radius">
                          <v-list-item>
                            <v-list-item-avatar
                              size="40"
                              tile
                              @click="sheet.form = true; reset(item);"
                              style="border: 2px solid #ffffff; border-radius: 5px !important;">
                              <v-img
                                contain
                                width="100%"
                                height="100%"
                                alt="bidanvitacare"
                                :src="item.image_url !== '' ? item.image_url : require('@/assets/image/image_default.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-list-item-avatar>

                            <v-list-item-content @click="sheet.form = true; reset(item);">
                              <v-list-item-title
                                class="body-2 font-weight-bold text-capitalize"
                                style="margin-bottom: 7px;">
                                {{ item.name }}
                              </v-list-item-title>

                              <v-list-item-subtitle
                                class="body-2">
                                <span
                                  :class="item.special_price > 0 ? 'text-decoration-line-through red--text' : ''">
                                  {{ item.retail_price | price }}
                                </span>

                                <span v-if="item.special_price > 0"
                                  class="ml-2">
                                  {{ item.special_price | price }}
                                </span>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-icon
                                @click.stop="dialog.deletes_transport = true; id_items = item.id;"
                                color="red">
                                mdi-delete
                              </v-icon>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <div v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
                class="text-center my-4">
                <v-btn
                  @click="limit += 10"
                  :loading="process.limit"
                  small
                  rounded
                  outlined
                  elevation="0"
                  :color="set_color"
                  class="text-capitalize"
                  :style="`color: ${set_color};`">
                  Lebih banyak
                </v-btn>
              </div>

              <Empty v-if="!process.load && pagination.total_page < 1"
                class="py-6"
                margin="my-16"
                size="125"
                message="Transport" />
            </v-card-text>
          </v-card>

          <v-card v-if="selected.menu === 'fee_therapist_percent'"
            flat
            color="transparent">
            <v-card-text
              class="pt-1">
              <ValidationObserver
                ref="form">
                <v-skeleton-loader
                  v-for="(item, index) in process.load ? 1 : 1"
                  :key="index"
                  :loading="process.load"
                  type="article, actions">
                  <template>
                    <div>
                      <div
                        class="body-2 font-weight-bold black--text mb-2">
                        Konfigurasi Fee Terapis
                      </div>

                      <div
                        class="body-2 line-text-second mb-4">
                        Fee untuk terapis sebesar <strong class="black--text">{{ form.value }}%</strong>, jika ingin mengubah persentase fee silahkan masukkan data persen dibawah, kemudian klik <b class="font-italic">Simpan</b>.
                      </div>

                      <div>
                        <v-form>
                          <ValidationProvider
                            name="Fee Terapis"
                            rules="required|min:0"
                            v-slot="{ errors, valid }">
                            <div class="body-2 mb-2">
                              <span class="red--text">*</span>
                              Fee Terapis
                              <!-- <span class="error--text">
                                (wajib diisi)
                              </span> -->
                            </div>

                            <v-text-field
                              v-on:keyup.enter="save_config"
                              type="number"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                              min="0"
                              filled
                              rounded
                              dense
                              :color="set_color"
                              placeholder="Contoh. 50"
                              :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                              @focus="$event.target.select()"
                              @keyup="form.value = form.value === '' || parseInt(form.value) < 1 ? 0 : form.value;"
                              @blur="form.value = form.value === '' || parseInt(form.value) < 1 ? 0 : form.value;"


                              v-model="form.value"
                              :success="valid"
                              :error-messages="errors"
                              required>
                            </v-text-field>
                          </ValidationProvider>
                        </v-form>

                        <div v-if="message.error.length > 0"
                          class="text-center my-2 d-flex align-center">
                          <div
                            class="error--text">
                            <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                            <span
                              class="ml-1">
                              {{ message.error }}
                            </span>
                          </div>
                        </div>

                        <v-btn
                          :disabled="process.form"
                          :loading="process.form"
                          v-on:keyup.enter="save_config"
                          @click="save_config"
                          block
                          large
                          rounded
                          elevation="0"
                          class="body-1 font-weight-bold white--text text-capitalize btn_save">
                          <div
                            class="btn_save_in d-flex align-center justify-center">
                            <div>
                              <v-img
                                contain
                                width="20"
                                height="20"
                                alt="bidanvitacare"
                                :src="require('@/assets/icon/persen.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </div>

                            <div
                              class="ml-2">
                              Simpan Fee Terapis
                            </div>
                          </div>
                        </v-btn>
                      </div>
                    </div>
                  </template>
                </v-skeleton-loader>
              </ValidationObserver>
            </v-card-text>
          </v-card>

          <v-card v-if="selected.menu === 'visi'"
            flat
            color="transparent">
            <v-card-text
              class="pt-1">
              <ValidationObserver
                ref="form">
                <v-skeleton-loader
                  v-for="(item, index) in process.load ? 1 : 1"
                  :key="index"
                  :loading="process.load"
                  type="article, actions">
                  <template>
                    <div>
                      <div
                        class="body-2 font-weight-bold black--text mb-2">
                        Konfigurasi Visi
                      </div>

                      <div
                        class="body-2 line-text-second mb-4">
                        Jika ingin mengubah text visi silahkan masukkan data dibawah, kemudian klik <b class="font-italic">Simpan</b>.
                      </div>

                      <div>
                        <v-form>
                          <quill-editor
                            ref="myTextEditor"
                            class="mb-6"
                            :options="{
                              placeholder: 'Tuliskan visi disini . . .'
                            }"
                            v-model="form.value">
                          </quill-editor>
                        </v-form>

                        <div v-if="message.error.length > 0"
                          class="text-center my-2 d-flex align-center">
                          <div
                            class="error--text">
                            <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                            <span
                              class="ml-1">
                              {{ message.error }}
                            </span>
                          </div>
                        </div>

                        <v-btn
                          :disabled="process.form"
                          :loading="process.form"
                          v-on:keyup.enter="save_config"
                          @click="save_config"
                          block
                          large
                          rounded
                          elevation="0"
                          class="body-1 font-weight-bold white--text text-capitalize btn_save">
                          <div
                            class="btn_save_in d-flex align-center justify-center">
                            <div>
                              <v-img
                                contain
                                width="20"
                                height="20"
                                alt="bidanvitacare"
                                :src="require('@/assets/icon/text_box.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </div>

                            <div
                              class="ml-2">
                              Simpan Visi
                            </div>
                          </div>
                        </v-btn>
                      </div>
                    </div>
                  </template>
                </v-skeleton-loader>
              </ValidationObserver>
            </v-card-text>
          </v-card>

          <v-card v-if="selected.menu === 'misi'"
            flat
            color="transparent">
            <v-card-text
              class="pt-1">
              <ValidationObserver
                ref="form">
                <v-skeleton-loader
                  v-for="(item, index) in process.load ? 1 : 1"
                  :key="index"
                  :loading="process.load"
                  type="article, actions">
                  <template>
                    <div>
                      <div
                        class="body-2 font-weight-bold black--text mb-2">
                        Konfigurasi Misi
                      </div>

                      <div
                        class="body-2 line-text-second mb-4">
                        Jika ingin mengubah text misi silahkan masukkan data dibawah, kemudian klik <b class="font-italic">Simpan</b>.
                      </div>

                      <div>
                        <v-form>
                          <quill-editor
                            ref="myTextEditor"
                            class="mb-6"
                            :options="{
                              placeholder: 'Tuliskan misi disini . . .'
                            }"
                            v-model="form.value">
                          </quill-editor>
                        </v-form>

                        <div v-if="message.error.length > 0"
                          class="text-center my-2 d-flex align-center">
                          <div
                            class="error--text">
                            <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                            <span
                              class="ml-1">
                              {{ message.error }}
                            </span>
                          </div>
                        </div>

                        <v-btn
                          :disabled="process.form"
                          :loading="process.form"
                          v-on:keyup.enter="save_config"
                          @click="save_config"
                          block
                          large
                          rounded
                          elevation="0"
                          class="body-1 font-weight-bold white--text text-capitalize btn_save">
                          <div
                            class="btn_save_in d-flex align-center justify-center">
                            <div>
                              <v-img
                                contain
                                width="20"
                                height="20"
                                alt="bidanvitacare"
                                :src="require('@/assets/icon/text_box.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </div>

                            <div
                              class="ml-2">
                              Simpan Misi
                            </div>
                          </div>
                        </v-btn>
                      </div>
                    </div>
                  </template>
                </v-skeleton-loader>
              </ValidationObserver>
            </v-card-text>
          </v-card>

          <v-card v-if="selected.menu === 'value'"
            flat
            color="transparent">
            <v-card-text
              class="pt-1">
              <ValidationObserver
                ref="form">
                <v-skeleton-loader
                  v-for="(item, index) in process.load ? 1 : 1"
                  :key="index"
                  :loading="process.load"
                  type="article, actions">
                  <template>
                    <div>
                      <div
                        class="body-2 font-weight-bold black--text mb-2">
                        Konfigurasi Value
                      </div>

                      <div
                        class="body-2 line-text-second mb-4">
                        Jika ingin mengubah text value silahkan masukkan data dibawah, kemudian klik <b class="font-italic">Simpan</b>.
                      </div>

                      <div>
                        <v-form>
                          <quill-editor
                            ref="myTextEditor"
                            class="mb-6"
                            :options="{
                              placeholder: 'Tuliskan value disini . . .'
                            }"
                            v-model="form.value">
                          </quill-editor>
                        </v-form>

                        <div v-if="message.error.length > 0"
                          class="text-center my-2 d-flex align-center">
                          <div
                            class="error--text">
                            <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                            <span
                              class="ml-1">
                              {{ message.error }}
                            </span>
                          </div>
                        </div>

                        <v-btn
                          :disabled="process.form"
                          :loading="process.form"
                          v-on:keyup.enter="save_config"
                          @click="save_config"
                          block
                          large
                          rounded
                          elevation="0"
                          class="body-1 font-weight-bold white--text text-capitalize btn_save">
                          <div
                            class="btn_save_in d-flex align-center justify-center">
                            <div>
                              <v-img
                                contain
                                width="20"
                                height="20"
                                alt="bidanvitacare"
                                :src="require('@/assets/icon/text_box.png')"
                                class="ma-auto">
                                <template v-slot:placeholder>
                                  <v-row
                                    class="fill-height"
                                    align="center"
                                    justify="center">
                                    <v-progress-circular
                                      size="20"
                                      indeterminate
                                      :color="set_color">
                                    </v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </div>

                            <div
                              class="ml-2">
                              Simpan Value
                            </div>
                          </div>
                        </v-btn>
                      </div>
                    </div>
                  </template>
                </v-skeleton-loader>
              </ValidationObserver>
            </v-card-text>
          </v-card>

          <v-card v-if="selected.menu === 'promo'"
            flat
            color="transparent">
            <v-card-text
              class="pt-1">
              <v-text-field
                v-model="search"
                @click:clear="search = ''; fetchPromo();"
                v-on:keyup.enter="fetchPromo()"
                filled
                rounded
                dense
                single-line
                hide-details
                :color="set_color"
                prepend-inner-icon="mdi-magnify"
                placeholder="Cari data yang Anda butuhkan ?"
                clearable>
              </v-text-field>

              <v-btn
                @click="sheet.form_promo= true; reset_promo();"
                block
                large
                rounded
                elevation="0"
                class="body-1 font-weight-bold white--text text-capitalize btn_auth mt-4"
                :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color} 100%);`">
                <div
                  class="btn_auth_in d-flex align-center justify-center"
                  :style="`background: linear-gradient(180deg, ${set_color_rgb} 0%, ${set_color_rgb_up} 100%);`">
                  <div>
                    <v-img
                      contain
                      width="20"
                      height="20"
                      alt="bidanvitacare"
                      :src="require('@/assets/icon/add_plus.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            size="20"
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </div>

                  <div
                    class="ml-2">
                    Tambah Promo
                  </div>
                </div>
              </v-btn>
            </v-card-text>

            <v-card-text
              class="pb-0">
              <div
                class="body-2 font-weight-bold black--text d-flex align-center mb-2">
                <div>
                  Promo
                </div>

                <v-spacer />

                <div>

                </div>
              </div>

              <v-row>
                <v-col>
                  <v-skeleton-loader
                    v-for="(item, index) in process.load ? 5 : list"
                    :key="index"
                    :loading="process.load"
                    :class="index < list.length - 1 ? 'mb-4' : ''"
                    type="list-item-avatar-three-line">
                    <template>
                      <div>
                        <v-list
                          v-if="!process.load && list.length > 0"
                          flat
                          dense
                          color="#FFFFFF40"
                          class="border-radius">
                          <v-list-item
                            @click="sheet.detail_promo = true; fetchDetailPromo(item.id);">
                            <v-list-item-content>
                              <v-list-item-title
                                class="body-2 font-weight-bold text-capitalize"
                                style="margin-bottom: 7px;">
                                {{ item.title }}
                              </v-list-item-title>

                              <v-list-item-subtitle
                                class="font-weight-light line-text-second black--text">
                                <div
                                  class="d-flex align-center">
                                  <div
                                    class="caption"
                                    style="opacity: 60%; margin-bottom: 1px;">
                                    Diskon
                                  </div>

                                  <v-spacer />

                                  <div
                                    class="body-2 black--text">
                                    {{ item.percent }} %
                                  </div>
                                </div>

                                <v-divider
                                  class="my-2"
                                  style="border-top: 1px dashed #bbb;" />

                                <div
                                  class="d-flex align-center mb-2">
                                  <div
                                    class="caption"
                                    style="opacity: 60%; margin-bottom: 1px;">
                                    Mulai
                                  </div>

                                  <v-spacer />

                                  <div
                                    class="body-2 green--text">
                                    {{ item.start_date | date }}
                                  </div>
                                </div>

                                <div
                                  class="d-flex align-center">
                                  <div
                                    class="caption"
                                    style="opacity: 60%; margin-bottom: 1px;">
                                    Selesai
                                  </div>

                                  <v-spacer />

                                  <div
                                    class="body-2 red--text">
                                    {{ item.end_date | date }}
                                  </div>
                                </div>

                                <v-divider
                                  class="my-2"
                                  style="border-top: 1px dashed #bbb;" />

                                <div
                                  class="d-flex align-center">
                                  <div
                                    class="caption"
                                    style="opacity: 60%; margin-bottom: 1px;">
                                    Tipe
                                  </div>

                                  <v-spacer />

                                  <div
                                    class="body-2 black--text">
                                    {{ item.type === 'item' ? 'Diskon Per Layanan / Produk' : 'Diskon Total Transaksi' }}
                                  </div>
                                </div>
                              </v-list-item-subtitle>

                              <v-list-item-subtitle v-if="item.items.length > 0"
                                class="font-weight-light line-text-second black--text pa-3 mt-2"
                                style="border: 2px dashed #bbb; border-radius: 10px;">
                                <ul
                                  style="position: relative; left: -10px; opacity: 60%;">
                                  <li
                                    v-for="(sub_item, sub_index) in item.items"
                                    :key="sub_index"
                                    :class="sub_index < item.items.length - 1 ? 'mb-1' : ''"
                                    v-html="sub_item.item_name">
                                  </li>
                                </ul>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </template>
                  </v-skeleton-loader>
                </v-col>
              </v-row>

              <div v-if="!process.load && pagination.total_data > 10 && limit < pagination.total_data"
                class="text-center my-4">
                <v-btn
                  @click="limit += 10"
                  :loading="process.limit"
                  small
                  rounded
                  outlined
                  elevation="0"
                  :color="set_color"
                  class="text-capitalize"
                  :style="`color: ${set_color};`">
                  Lebih banyak
                </v-btn>
              </div>

              <Empty v-if="!process.load && pagination.total_page < 1"
                class="py-6"
                margin="my-16"
                size="125"
                message="Promo" />
            </v-card-text>
          </v-card>
        </section>

        <!-- transport -->
        <v-bottom-sheet
          v-model="sheet.form"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.form = false; reset();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.form = false; reset();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  {{ form.id === '' ? 'Tambah' : 'Ubah' }}
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 205px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text
                  class="d-flex align-center pt-16 mt-4">
                  <v-avatar
                    tile
                    size="150">
                    <v-img
                      width="100%"
                      height="100%"
                      style="box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
                            border: 5px solid #0000000d;
                            border-radius: 10px;"
                      alt="bidanvitacare"
                      :src="form.image_url !== '' ? form.image_url : require('@/assets/image/image_default.png')"
                      class="ma-auto">
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height"
                          align="center"
                          justify="center">
                          <v-progress-circular
                            indeterminate
                            :color="set_color">
                          </v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                  </v-avatar>

                  <div>
                    <upload-folder
                      v-model="upload.folder">
                      <div slot="activator">
                        <v-btn
                          :disabled="process.file"
                          :loading="process.file"
                          elevation="0"
                          rounded
                          block
                          large
                          color="#0000000d"
                          class="body-1 font-weight-bold text-capitalize d-flex align-center"
                          style="border-radius: 0px 50px 50px 0px;"
                          :style="`color: ${set_color};`">
                          <v-icon
                            size="24"
                            :color="set_color">
                            mdi-camera
                          </v-icon>

                          <span
                            class="ml-1">
                            Gambar
                          </span>
                        </v-btn>
                      </div>
                    </upload-folder>
                  </div>
                </v-card-text>

                <v-card-text>
                  <ValidationObserver
                    ref="form">
                    <v-form>
                      <ValidationProvider
                        name="Nama Transport"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Nama Transport
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. Area 1 (0 - 5 Km)"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form.name"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <ValidationProvider
                        name="Biaya"
                        rules="required|min:0"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Biaya
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save"
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 50.000"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"

                          v-model="form.retail_price"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>
                    </v-form>
                  </ValidationObserver>

                  <div v-if="message.error.length > 0"
                    class="text-center my-2 d-flex align-center">
                    <div
                      class="error--text">
                      <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                      <span
                        class="ml-1">
                        {{ message.error }}
                      </span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="150"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col
                      cols="3"
                      class="pb-0">
                      <v-img
                        contain
                        alt="bidanvitacare"
                        :src="require('@/assets/image/footer/settings.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col
                      cols="9"
                      class="black--text pb-0">
                      <div>
                        <div
                          class="body-2 line-text-second black--text"
                          style="opacity: 60%;">
                          Masukkan data untuk membuat transport di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="mt-4">
                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        :disabled="process.form"
                        :loading="process.form"
                        v-on:keyup.enter="save"
                        @click="save"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-check-bold
                          </v-icon>

                          Simpan
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        @click="sheet.form = false; reset();"
                        :disabled="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                        <div
                          class="btn_cancel_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-close-thick
                          </v-icon>

                          Batal
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>

        <!-- promo -->
        <v-bottom-sheet
          v-model="sheet.form_promo"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.form_promo = false; reset_promo();"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.form_promo = false; reset_promo();"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  {{ form_promo.id === '' ? 'Tambah' : 'Ubah' }}
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 205px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text
                  class="pt-16 mt-4">
                  <ValidationObserver
                    ref="form">
                    <v-form>
                      <ValidationProvider
                        name="Nama Promo"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Nama Promo
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save_promo"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. Promo Idul Adha"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          v-model="form_promo.title"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <ValidationProvider
                        name="Tipe"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Tipe
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-select
                          v-on:keyup.enter="save_promo"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. Diskon Per Item"

                          :items="[
                            {
                              text: 'Diskon Per Layanan / Produk',
                              value: 'item'
                            },
                            {
                              text: 'Diskon Total Transaksi',
                              value: 'total'
                            }
                          ]"
                          item-text="text"
                          item-value="value"
                          :item-color="set_color"

                          @change="form_promo.items = [], form_promo.items_layanan = []; form_promo.items_produk = [];"

                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"
                          v-model="form_promo.type"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-select>
                      </ValidationProvider>

                      <div>
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Mulai
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-menu
                          ref="start_date"
                          min-width="290px"
                          max-width="290px"
                          transition="scale-transition"
                          offset-y
                          :nudge-right="40"
                          :close-on-content-click="false"
                          v-model="picker.start">
                          <template v-slot:activator="{ on, attrs }">
                            <ValidationProvider
                              name="Mulai"
                              rules="required"
                              v-slot="{ errors, valid }">
                              <v-text-field
                                v-on:keyup.enter="save_promo"
                                readonly
                                filled
                                rounded
                                dense
                                :color="set_color"
                                placeholder="Contoh. 2023-01-01"

                                :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                                clearable
                                @click:clear="form_promo.start_date = ''"
                                v-on="on"
                                v-bind="attrs"

                                v-model="form_promo.start_date"
                                :success="valid"
                                :error-messages="errors"
                                required>
                              </v-text-field>
                            </ValidationProvider>
                          </template>

                          <v-date-picker
                            :color="set_color"
                            v-model="form_promo.start_date"
                            @input="picker.start = false">
                          </v-date-picker>
                        </v-menu>
                      </div>

                      <div>
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Selesai
                          <!-- <span>
                            (boleh dikosongi)
                          </span> -->
                        </div>

                        <v-menu
                          ref="end_date"
                          min-width="290px"
                          max-width="290px"
                          transition="scale-transition"
                          offset-y
                          :nudge-right="40"
                          :close-on-content-click="false"
                          v-model="picker.end">
                          <template v-slot:activator="{ on, attrs }">
                            <ValidationProvider
                              name="Selesai"
                              rules="required"
                              v-slot="{ errors, valid }">
                              <v-text-field
                                v-on:keyup.enter="save_promo"
                                readonly
                                filled
                                rounded
                                dense
                                :color="set_color"
                                placeholder="Contoh. 2023-12-01"

                                :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                                clearable
                                @click:clear="form_promo.end_date = ''"
                                v-on="on"
                                v-bind="attrs"

                                v-model="form_promo.end_date"
                                :success="form_promo.end_date !== '' && form_promo.end_date !== null && valid"
                                :error-messages="errors"
                                required>
                              </v-text-field>
                            </ValidationProvider>
                          </template>

                          <v-date-picker
                            :min="form_promo.start_date"
                            :color="set_color"
                            v-model="form_promo.end_date"
                            @input="picker.end = false">
                          </v-date-picker>
                        </v-menu>
                      </div>

                      <ValidationProvider
                        name="Diskon (%)"
                        rules="required|min:0|max_100:100"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Diskon (%)
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-text-field
                          v-on:keyup.enter="save_promo"
                          type="number"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"

                          min="0"
                          filled
                          rounded
                          dense
                          :color="set_color"
                          placeholder="Contoh. 10"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          @focus="$event.target.select()"

                          v-model="form_promo.percent"
                          :success="valid"
                          :error-messages="errors"
                          required>
                        </v-text-field>
                      </ValidationProvider>

                      <ValidationProvider v-if="form_promo.type === 'item'"
                        name="Layanan"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Layanan
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-autocomplete
                          v-on:keyup.enter="save_promo"
                          filled
                          rounded
                          dense

                          :color="set_color"
                          placeholder="Contoh. Pijat Badan Keseluruhan"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          :search-input.sync="autocomplete.layanan"
                          :loading="process.layanan"

                          :items="layanan"
                          item-value="id"
                          item-text="name"
                          :item-color="set_color"

                          :no-filter="false"

                          multiple
                          chips

                          return-object

                          v-model="form_promo.items_layanan"
                          :success="valid"
                          :error-messages="errors"
                          required>
                          <!-- <template v-slot:item="data">
                            <span
                              class="body-2 text-capitalize">
                              {{ data.item.name }}
                            </span>
                          </template>

                          <template v-slot:selection="data">
                            <v-chip
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              close
                              @click="data.select"
                              @click:close="remove_layanan(data.item)">
                              <v-avatar left>
                                <v-img :src="data.item.avatar"></v-img>
                              </v-avatar>
                              {{ data.item.name }}
                            </v-chip>
                          </template> -->
                        </v-autocomplete>
                      </ValidationProvider>

                      <ValidationProvider v-if="form_promo.type === 'item'"
                        name="Produk"
                        rules="required"
                        v-slot="{ errors, valid }">
                        <div class="body-2 mb-2">
                          <span class="red--text">*</span>
                          Produk
                          <!-- <span class="error--text">
                            (wajib diisi)
                          </span> -->
                        </div>

                        <v-autocomplete
                          v-on:keyup.enter="save_promo"
                          filled
                          rounded
                          dense

                          :color="set_color"
                          placeholder="Contoh. Amani Yogurt"
                          :append-icon="valid ? 'mdi-check-circle-outline' : ''"

                          :search-input.sync="autocomplete.produk"
                          :loading="process.produk"

                          :items="produk"
                          item-value="id"
                          item-text="name"
                          :item-color="set_color"

                          :no-filter="false"

                          multiple
                          chips

                          return-object

                          v-model="form_promo.items_produk"
                          :success="valid"
                          :error-messages="errors"
                          required>
                          <!-- <template v-slot:item="data">
                            <span
                              class="body-2 text-capitalize">
                              {{ data.item.name }}
                            </span>
                          </template>

                          <template v-slot:selection="data">
                            <v-chip
                              v-bind="data.attrs"
                              :input-value="data.selected"
                              close
                              @click="data.select"
                              @click:close="remove_produk(data.item)">
                              <v-avatar left>
                                <v-img :src="data.item.avatar"></v-img>
                              </v-avatar>
                              {{ data.item.name }}
                            </v-chip>
                          </template> -->
                        </v-autocomplete>
                      </ValidationProvider>
                    </v-form>
                  </ValidationObserver>

                  <div v-if="message.error.length > 0"
                    class="text-center my-2 d-flex align-center">
                    <div
                      class="error--text">
                      <v-icon size="18" color="red">mdi-alert-circle-outline</v-icon>

                      <span
                        class="ml-1">
                        {{ message.error }}
                      </span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="150"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row
                    align="center">
                    <v-col
                      cols="3"
                      class="pb-0">
                      <v-img
                        contain
                        alt="bidanvitacare"
                        :src="require('@/assets/image/footer/settings.png')"
                        class="ma-auto">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              :color="set_color">
                            </v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>

                    <v-col
                      cols="9"
                      class="black--text pb-0">
                      <div>
                        <div
                          class="body-2 line-text-second black--text"
                          style="opacity: 60%;">
                          Masukkan data untuk membuat promo di Bidanvitacare.id, kemudian klik <b class="font-italic">Simpan</b>.
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row
                    class="mt-4">
                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        :disabled="process.form"
                        :loading="process.form"
                        v-on:keyup.enter="save_promo"
                        @click="save_promo"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_save">
                        <div
                          class="btn_save_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-check-bold
                          </v-icon>

                          Simpan
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="6"
                      class="pb-0">
                      <v-btn
                        @click="sheet.form_promo = false; reset_promo();"
                        :disabled="process.form"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_cancel">
                        <div
                          class="btn_cancel_in">
                          <v-icon
                            size="20"
                            style="position: absolute; left: 8px; top: 0; bottom: 0;">
                            mdi-close-thick
                          </v-icon>

                          Batal
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>

        <v-bottom-sheet
          v-model="sheet.detail_promo"
          hide-overlay
          persistent
          fullscreen
          scrollable>
          <v-card
            flat
            class="background">
            <div v-if="Object.keys(detail_promo).length > 0"
              class="blur">
              <v-card-title
                class="pa-4"
                style="border-radius: 0px 0px 15px 15px;
                      position: absolute;
                      width: 100%;
                      z-index: 2;
                      background: #f9dee2;">
                <v-icon
                  @click="sheet.detail_promo = false"
                  :color="set_color"
                  class="cursor-pointer ">
                  mdi-arrow-left-thick
                </v-icon>

                <span
                  @click="sheet.detail_promo = false"
                  class="body-1 font-weight-bold text-capitalize cursor-pointer one-line ml-1"
                  :style="`color: ${set_color};`"
                  style="width: 90%;">
                  {{ detail_promo.title }}
                </span>
              </v-card-title>

              <v-card
                flat
                height="calc(100vh - 80px)"
                style="overflow: auto;"
                color="transparent">
                <v-card-text
                  class="pt-16 mt-4">
                  <div
                    class="body-2 font-weight-bold black--text mb-2">
                    {{ detail_promo.title }}
                  </div>

                  <div
                    class="d-flex align-center">
                    <div
                      class="body-2">
                      Diskon
                    </div>

                    <v-spacer />

                    <div
                      class="body-2 black--text">
                      {{ detail_promo.percent }} %
                    </div>
                  </div>

                  <v-divider
                    class="my-2"
                    style="border-top: 1px dashed #bbb;" />

                  <div
                    class="d-flex align-center mb-2">
                    <div
                      class="body-2">
                      Mulai
                    </div>

                    <v-spacer />

                    <div
                      class="body-2 green--text">
                      {{ detail_promo.start_date | date }}
                    </div>
                  </div>

                  <div
                    class="d-flex align-center">
                    <div
                      class="body-2">
                      Selesai
                    </div>

                    <v-spacer />

                    <div
                      class="body-2 red--text">
                      {{ detail_promo.end_date | date }}
                    </div>
                  </div>

                  <v-divider
                    class="my-2"
                    style="border-top: 1px dashed #bbb;" />

                  <div
                    class="d-flex align-center">
                    <div
                      class="body-2">
                      Tipe
                    </div>

                    <v-spacer />

                    <div
                      class="body-2 black--text">
                      {{ detail_promo.type === 'item' ? 'Diskon Per Layanan / Produk' : 'Diskon Total Transaksi' }}
                    </div>
                  </div>

                  <div v-if="detail_promo.items.length > 0"
                    class="font-weight-light line-text-second black--text pa-3 mt-4"
                    style="border: 2px dashed #bbb; border-radius: 10px;">
                    <ul
                      style="position: relative; left: -10px; opacity: 60%;">
                      <li
                        v-for="(item, index) in detail_promo.items"
                        :key="index"
                        :class="index < detail_promo.items.length - 1 ? 'mb-1' : ''"
                        v-html="item.item_name">
                      </li>
                    </ul>
                  </div>
                </v-card-text>
              </v-card>

              <v-card
                flat
                color="#FFFFFF"
                min-height="50"
                style="border-radius: 15px 15px 0px 0px;
                      position: sticky;
                      bottom: 0;
                      width: 460px;
                      z-index: 1;">
                <v-card-text
                  class="pa-4">
                  <v-row>
                    <v-col
                      cols="6"
                      class="py-0">
                      <v-btn
                        @click="sheet.form_promo = true; reset_promo(detail_promo);"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                        <div
                          class="btn_grey_in d-flex align-center justify-center">
                          <div>
                            <v-img
                              contain
                              width="20"
                              height="20"
                              alt="bidanvitacare"
                              :src="require('@/assets/icon/edit_pencil.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div
                            class="blue--text ml-2">
                            Ubah
                          </div>
                        </div>
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="6"
                      class="py-0">
                      <v-btn
                        @click="dialog.deletes = true"
                        block
                        large
                        rounded
                        elevation="0"
                        class="body-1 font-weight-bold white--text text-capitalize btn_grey">
                        <div
                          class="btn_grey_in d-flex align-center justify-center">
                          <div>
                            <v-img
                              contain
                              width="20"
                              height="20"
                              alt="bidanvitacare"
                              :src="require('@/assets/icon/hapus.png')"
                              class="ma-auto">
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height"
                                  align="center"
                                  justify="center">
                                  <v-progress-circular
                                    size="20"
                                    indeterminate
                                    :color="set_color">
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </div>

                          <div
                            class="red--text ml-2">
                            Hapus
                          </div>
                        </div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-bottom-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
  import { createSEOMeta } from '@/utils/seo'
  export default {
    middleware: 'authenticated',
    data () {
      return {
        dialog: {
          deletes: false,
          deletes_transport: false
        },

        sheet: {
          form: false,
          detail: false,

          form_promo: false,
          detail_promo: false
        },

        search: '',

        selected: {
          menu: 'transport'
        },

        menu: [
          {
            text: 'Transport',
            value: 'transport'
          },
          {
            text: 'Fee Terapis',
            value: 'fee_therapist_percent'
          },
          {
            text: 'Visi',
            value: 'visi'
          },
          {
            text: 'Misi',
            value: 'misi'
          },
          {
            text: 'Value',
            value: 'value'
          },
          {
            text: 'Promo',
            value: 'promo'
          }
        ],

        autocomplete: {
          layanan: '',
          produk: ''
        },

        detail_promo: {},

        layanan: [],
        produk: [],

        list: [],
        pagination: {},

        limit: 10,

        upload: {
          folder: null
        },
        id_items: '',

        form: {
          id: '',
          name: '',
          image_url: '',
          type: 'transport',
          duration_minutes: 0,
          retail_price: 0,
          special_price: 0,
          stock: 0,
          stock_min: 0,

          // STOK PER TOKO
          store: [],

          key: 'fee_therapist_percent',
          value: 0
        },

        form_promo: {
          id: '',
          type: '',
          title: '',
          start_date: '',
          end_date: '',
          percent: 0,
          items: [],

          items_layanan: [],
          items_produk: []
        },

        picker: {
          start: false,
          end: false
        },

        process: {
          load: false,
          form: false,
          file: false,

          limit: false,

          layanan: false,
          produk: false
        },

        message: {
          success: '',
          error: ''
        },
        // SEO
        content: {
          url: '/konfigurasi',
          title: 'Konfigurasi',
          description: 'Konfigurasi Bidanvitacare.id',
          image: require('@/assets/logo/favicon.png')
        }
      }
    },
    head () {
      return {
        title: this.content.title,
        meta: createSEOMeta(this.content)
      }
    },
    components: {
      UploadFolder: () => import(/* webpackPrefetch: true */ '@/components/upload/folder.vue'),
      Empty: () => import(/* webpackPrefetch: true */ '@/components/Empty')
    },
    watch: {
      'selected.menu': function (val) {
        if (val === 'transport') {
          this.fetch()
        } else if (val === 'promo') {
          this.fetchPromo()
        } else {
          this.form.key = val
          this.form.value = val === 'fee_therapist_percent' ? 0 : ''

          this.fetchConfig()
        }
      },

      'upload.folder': {
        handler: function () {
          if (this.upload.folder) {
            this.process.file = this.upload.folder.process

            if (!this.process.file) {
              // CALLBACK FROM UPLOAD IMAGE

              this.form.image_url = this.upload.folder.fileuri
            }
          }
        },
        deep: true
      },

      'limit': function() {
        this.process.limit = true

        if (val === 'transport') {
          this.fetch('limit')
        } else if (val === 'promo') {
          this.fetchPromo('limit')
        }
      },

      'autocomplete.layanan': function() {
        this.fetchLayanan()
      },
      'autocomplete.produk': function() {
        this.fetchProduk()
      }
    },
    computed: {
      rps () {
        return this.$vuetify.breakpoint.name
      },
      user () {
        return this.$store.state.member.auth.user
      },
      isDay () {
        let hours = (new Date()).getHours(),
            day = hours >= 5 && hours <= 8 ? 'pagi' : hours > 8 && hours <= 14 ? 'siang' : hours > 14 && hours <= 18 ? 'sore' : 'malam'
        return day
      },
      set_color () {
        return this.$store.state.header.config.color
      },
      set_color_rgb () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g}, ${b}, 0.97)`
      },
      set_color_rgb_up () {
        let [r, g, b] = this.$store.state.header.config.color.match(/\w\w/g).map(x => parseInt(x, 16))

        return `rgba(${r}, ${g+30}, ${b+30}, 0.97)`
      }
    },
    mounted () {
      this.fetch()
    },
    methods: {
      async fetch (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search,
          limit: this.limit,
          'type[eq]': 'transport'
        }

        await this.$axios.$get(`${process.env.API}admin/item`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },
      async fetchConfig () {
        this.process.load = true

        let params = {
          key: this.selected.menu
        }

        await this.$axios.$get(`${process.env.API}admin/config`, { params })
        .then((response) => {
          this.process.load = false

          if (response.status === 200) {
            this.form.value = response.results.data[0].value
          }
        })
      },

      reset (item) {
        if (this.$refs.form) this.$refs.form.reset()

        this.form = {
          id: item !== undefined ? item.id : '',
          name: item !== undefined ? item.name : '',
          type: item !== undefined ? item.type : 'transport',
          image_url: item !== undefined ? item.image_url : '',
          duration_minutes: item !== undefined ? item.duration_minutes : 0,
          retail_price: item !== undefined ? item.retail_price : 0,
          special_price: item !== undefined ? item.special_price : 0,
          stock: item !== undefined ? item.stock : 0,
          stock_min: item !== undefined ? item.stock_min : 0,

          // STOK PER TOKO
          store: item !== undefined ? item.store : [],

          key: this.selected.menu,
          value: 0
        }

        this.process.form = false

        this.message.error = ''
      },
      async save () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          let url = this.form.id === '' ? 'admin/item/add' : 'admin/item/update'

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}${url}`, this.form)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              this.sheet.form = false

              this.fetch()
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },
      async save_config () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}admin/config/update`, this.form)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              this.sheet.form = false

              this.fetchConfig()
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      // promo
      async fetchPromo (type) {
        this.process.load = type === undefined ? true : false

        let params = {
          search: this.search,
          limit: this.limit
        }

        await this.$axios.$get(`${process.env.API}admin/promo/data`, { params })
        .then((response) => {
          this.process.load = false

          this.process.limit = false

          if (response.status === 200) {
            this.list = response.results.data
            this.pagination = response.results.pagination
          }
        })
      },
      async fetchDetailPromo (id) {
        let params = {
          id: id
        }

        await this.$axios.$get(`${process.env.API}admin/promo/detail`, { params })
        .then((response) => {

          if (response.status === 200) {
            this.detail_promo = response.results.data
          }
        })
      },
      async fetchLayanan () {
        this.process.layanan = true

        let params = {
          search: this.autocomplete.layanan,
          'type[eq]': 'service',
          limit: 20
        }

        this.layanan = []

        await this.$axios.$get(`${process.env.API}admin/item`, { params })
        .then((response) => {
          this.process.layanan = false

          if (response.status === 200) {
            response.results.data.map(obj => {
              this.layanan.push({
                id: obj.id,
                name: obj.name
              })
            })
          }
        })
      },
      async fetchProduk () {
        this.process.produk = true

        let params = {
          search: this.autocomplete.produk,
          'type[eq]': 'product',
          limit: 20
        }

        this.produk = []

        await this.$axios.$get(`${process.env.API}admin/item`, { params })
        .then((response) => {
          this.process.produk = false

          if (response.status === 200) {
            response.results.data.map(obj => {
              this.produk.push({
                id: obj.id,
                name: obj.name
              })
            })
          }
        })
      },
      reset_promo (item) {
        if (this.$refs.form) this.$refs.form.reset()

        this.form_promo = {
          id: item !== undefined ? item.id : '',
          type: item !== undefined ? item.type : '',
          title: item !== undefined ? item.title : '',
          start_date: item !== undefined ? item.start_date : '',
          end_date: item !== undefined ? item.end_date : '',
          percent: item !== undefined ? item.percent : 0,
          items: item !== undefined ? item.items : [],

          items_layanan: [],
          items_produk: []
        }

        if (this.form_promo.id !== '') {
          this.form_promo.items.map(obj => {
            if (obj.item_type === 'service') {
              this.form_promo.items_layanan.push({
                id: obj.item_id,
                name: obj.item_name
              })
            } else {
              this.form_promo.items_produk.push({
                id: obj.item_id,
                name: obj.item_name
              })
            }
          })
        }

        this.process.form = false

        this.message.error = ''
      },
      remove_layanan (item) {
        const index = this.form_promo.items_layanan.indexOf(item)
        console.log(index)
        if (index >= 0) this.form_promo.items_layanan.splice(index, 1)
      },
      remove_produk (item) {
        const index = this.form_promo.items_produk.indexOf(item)
        if (index >= 0) this.form_promo.items_produk.splice(index, 1)
      },
      async save_promo () {
        this.message.error = ''

        const isValid = await this.$refs.form.validate()
        if (isValid) {
          this.process.form = true

          let url = this.form_promo.id === '' ? 'admin/promo/add' : 'admin/promo/update'

          this.form_promo.items = [...this.form_promo.items_layanan, ...this.form_promo.items_produk]

          // SAVE TO REST API
          await this.$axios.$post(`${process.env.API}${url}`, this.form_promo)
          .then((response) => {

            if (response.status === 200) {
              this.process.form = false

              this.sheet.form_promo = false

              if (this.form_promo.id === '') {
                this.fetchPromo()
              } else {
                this.fetchDetailPromo(this.form_promo.id)
              }
            } else {
              this.process.form = false
              this.message.error = response.message
            }
          })

        } else {
          // AUTO FOCUS ON TEXT-FIELD
          setTimeout(() => {
            const errors = Object.entries(this.$refs.form.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error['value'].length)
            this.$refs.form.refs[errors[0]['key']].$el.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          }, 10)
        }
      },

      async deletes_promo () {
        this.message.error = ''

        this.process.form = true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/promo/delete`, {
          id: this.detail_promo.id
        })
        .then((response) => {

          this.process.form = false

          if (response.status === 200) {
            this.dialog.deletes = false

            this.sheet.detail_promo = false

            this.fetchPromo()
          } else {
            this.message.error = response.message
          }
        })
      },

      async deletes_transport () {
        this.message.error = ''

        this.process.form = true

        // SAVE TO REST API
        await this.$axios.$post(`${process.env.API}admin/item/delete`, {
          id: this.id_items
        })
        .then((response) => {

          this.process.form = false

          if (response.status === 200) {
            this.dialog.deletes_transport = false


            this.fetch()
          } else {
            this.message.error = response.message
          }
        })
      }
    }
  }
</script>
